import { version } from '../../package.json';
import Button from 'react-bootstrap/Button';
import classnames from 'classnames';

import { connect } from 'react-redux';
import { updateServiceWorker } from '../redux/Ui';
const mapStateToProps = state => {
  return {
    hasUpdate: state.ui.hasUpdate
  }
}
const mapDispatchToProps = dispatch => {
  return {
    updateServiceWorker: () => { dispatch(updateServiceWorker()); }
  }
}

const UpdateVersion = ({ hasUpdate, updateServiceWorker }) => {
  return (
    <div className={classnames("version", {"hasupdate": hasUpdate})}>
      <div className="version--inner">
        <div className="container px-3 px-md-5 py-3 text-small text-light">
          v {version}
          {hasUpdate && <Button size="sm" variant="light" className="ml-3" onClick={updateServiceWorker}>Update</Button>}
          {hasUpdate && <i className="d-none d-sm-inline-block ml-3">New version available. Click "Update" to install.</i>}
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateVersion);
