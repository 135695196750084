import { useState } from "react";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import Logo from './Logo';
import { Globe, TrashFill } from 'react-bootstrap-icons';
import {
    PATH_HOME,
    PATH_HELP,
    PATH_CONTACT,
    DIGITAL_ASSETS_URL
} from '../constants';
import UpdateVersion from './UpdateVersion';

import { connect } from 'react-redux';
const mapStateToProps = state => {
    return {
        country: state.user.data && state.user.data.country ? state.user.data.country : null
    };
}

const Footer = (props) => {
    
    const [showStorageModal, setShowStorageModal] = useState(false);
    const [clearedItems, setClearedItems] = useState(0); // force rerender after localStorage item is cleared
    
    const clearAllLocal = () => {
        Object.keys(localStorage).forEach((k) => {
            if (k.indexOf(process.env.REACT_APP_API) > -1) {
                localStorage.removeItem(k);
                setClearedItems(clearedItems + 1);
            }
        });
    }
    
    return (
        <div id="footer" className="bg-dark text-light py-3">
            <div className="container px-3 px-md-5">
                <Navbar className="px-0 py-0 mb-3 flex-wrap" variant="dark">
                    <Link to={PATH_HOME} className="navbar-brand order-0"><Logo /></Link>
                    <Nav className="mr-auto">
                        <Link to={PATH_HELP} className="nav-link">Help</Link>
                        <Link to={PATH_CONTACT} className="nav-link">Contact</Link>
                        {props.country && props.country === "AU" && <>
                            <a href="https://www.harpercollins.com.au/newsletter/" target="_blank" rel="noreferrer" className="nav-link">Newsletter Hub</a>
                            <a href="https://www.teachershub.com.au/" target="_blank" rel="noreferrer" className="nav-link">Teachers Hub</a>
                            <a href={DIGITAL_ASSETS_URL} target="_blank" rel="noreferrer" className="nav-link">Digital Assets</a>
                            <a href={"/cms/HEDS_Trading_Terms_Fees_OCT2023.pdf"} target="_blank" rel="noreferrer" className="nav-link">Trading Terms</a>
                        </>}
                        {props.country && props.country === "NZ" && <a href={"/cms/HEDS Trading Terms NZ_JUNE_2023_SCHEDULE_UPDATE_OCT_2024.pdf"} target="_blank" rel="noreferrer" className="nav-link">Trading Terms</a>}
                        <button type="button" className="nav-link" onClick={() => { setShowStorageModal(true); }}>Clear local storage</button>
                    </Nav>
                    <Nav className="flex-wrap">
                        <Nav.Link href="https://www.harpercollins.com.au" className="text-nowrap"><Globe /> www.harpercollins.com.au</Nav.Link>
                        <Nav.Link href="https://www.harpercollins.co.nz" className="text-nowrap pr-sm-0"><Globe /> www.harpercollins.co.nz</Nav.Link>
                    </Nav>
                </Navbar>
                <div className="text-center text-sm-right">
                    <p className="mb-2">Copyright {new Date().getFullYear()}. Harper Entertainment Distribution Services.</p>
                    <p>A division of Harper Collins Australia Pty Limited. ABN 36 009 913 517</p>
                </div>
            </div>
            <UpdateVersion />
            <Modal show={showStorageModal}>
                <Modal.Header>
                    <Modal.Title>Clear local storage</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>This site temporarily stores some data in your browser's local storage to save time when a page is loaded repeatedly. You can clear it here.</p>
                    <hr />
                    <Button variant="outline-primary" onClick={clearAllLocal}>Clear all</Button>
                    <hr />
                    {showStorageModal && <div>
                        {Object.keys(localStorage).map((k,i) => {
                            if (k.indexOf(process.env.REACT_APP_API) > -1) {
                                let label = "Product Information";
                                if (k.indexOf("/home") > -1 || k.indexOf("/contentpage") > -1) { label = "Page"; }
                                if (k.indexOf("/faqs") > -1) { label = "FAQs"; }
                                if (k.indexOf("/offers") > -1) { label = "Offers"; }
                                let text = <>{label} <small>({k.substring(process.env.REACT_APP_API.length)})</small></>;
                                return (
                                    <p key={`ls${i}`} className="localstorage-item"><Button onClick={() => { localStorage.removeItem(k); setClearedItems(clearedItems + 1); }}><TrashFill /></Button> {text}</p>
                                );
                            }
                            return null;
                        })}
                    </div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={() => { setShowStorageModal(false); }}>Cancel</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default connect(mapStateToProps)(Footer);
