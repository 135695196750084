import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { request } from '../functions/apiRequestWrapper';
import Book from './Book';
import Breadcrumb from '../components/Breadcrumb';
import Button from 'react-bootstrap/Button';
import { PATH_RELEASES } from '../constants';
import _findIndex from 'lodash/findIndex';

import { db } from '../db';

class BookInRelease extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoadingSection: false,
      sectionBooks: [],
      nextBook: null,
      prevBook: null,
      detailsOpen: false,
      sectionInfo: {},
      isFirstBook: true,
      displayMode: false,
      bookCrumbTitle: ""
    }
  }

  componentDidMount() {
    setTimeout(() => {window.scrollTo(0, 0);}, 100);
    const { match } = this.props;
    this.getReleaseSection(match.params.monthYear, match.params.orderFormId, match.params.publisher);
    document.addEventListener('keydown', this.handleKeyDown, false);
  }

  componentDidUpdate(prevProps) {
      const {match} = this.props;
      if (prevProps.match.params.isbn !== match.params.isbn) {
          this.getPrevNext();
      }
  }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown, false);
    }

  getReleaseSection = (monthYear, orderFormId, publisher) => {
    this.setState({ isLoadingSection: true });
    request(
      publisher ?
        `${process.env.REACT_APP_API}/1/releases/${monthYear}/${orderFormId}/publishers/${publisher}/products` :
        `${process.env.REACT_APP_API}/1/releases/${monthYear}/${orderFormId}/products`
    ).then((data) => {
      this.setState({ sectionBooks: data.products, sectionInfo: data.links, isLoadingSection: false });
      this.getPrevNext(data.products);
    }).catch((error) => {
        db.sections.get(orderFormId).then((offlineSection) => {
            if (offlineSection.products) {
                db.products.where("isbn").anyOf(offlineSection.products).toArray().then((offlineProducts) => {
                    this.setState({
                        sectionInfo: offlineSection,
                        sectionBooks: offlineProducts,
                        isLoadingSection: false
                    });
                    this.getPrevNext(offlineProducts);
                });
            } else {
                this.setState({
                    sectionInfo: offlineSection,
                    sectionBooks: [],
                    isLoadingSection: false
                });
            }
        }).catch(() => {
            this.setState({ isLoadingSection: false });
        });
    });
  }

    goToBook = (isbn) => {
        const { match } = this.props;
        let pub = "";
        if (match.params.publisher) {
            pub = `/publisher/${match.params.publisher}`;
        }
        this.setState({detailsOpen: true}, () => {
            this.props.history.push(`${PATH_RELEASES}/${match.params.monthYear}${pub}/${match.params.orderFormId}/${isbn}`);
        });
    }

    getPrevNext = (products) => {
        const { match } = this.props;
        if (typeof products === "undefined") { products = this.state.sectionBooks; }
        let idx = _findIndex(products, {isbn: match.params.isbn}); //console.log("getPrevNext idx", idx);
        let prev = false; let next = false;
        if (idx > 0) {
            prev = products[idx - 1];
        }
        if (idx < products.length - 1) {
            next = products[idx + 1];
        }
        this.setState({
            prevBook: prev ? prev.isbn : null,
            nextBook: next ? next.isbn : null
        });
    }

    handleKeyDown = (e) => {
        if (e.keyCode === 37 && this.state.prevBook) {
            // left arrow
            this.goToBook(this.state.prevBook);
        }
        if (e.keyCode === 39 && this.state.nextBook) {
            // right arrow
            this.goToBook(this.state.nextBook);
        }
        if (e.keyCode === 38) {
            this.setState({displayMode: true});
        }
        if ((e.keyCode === 27 || e.keyCode === 40) && this.state.displayMode) {
            this.setState({displayMode: false});
        }
    }

    scrollToBook = () => {
        setTimeout(() => {
            document.getElementById("booktopstop").scrollIntoView({behavior: "auto"});
        }, 100);
    }
    scrollToTop = () => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100);
    }
    
    toggleDisplayMode = () => {
        this.setState({displayMode: !this.state.displayMode});
        this.scrollToTop();
    }
    
    onLoadBook = (title) => {
        if (!this.state.isFirstBook) {
          if (this.state.displayMode) {
            this.scrollToTop();
          } else {
            this.scrollToBook();
          }
        }
        this.setState({isFirstBook: false, bookCrumbTitle: title ? title : ""});
    }

  render() {
    const { match } = this.props;
    const { sectionInfo, displayMode } = this.state;
    const monthYearFormatted = match.params.monthYear.replace('-', ' ').toLowerCase();
    let crumbs = [
      { link: PATH_RELEASES, label: "Releases" },
      { link: `${PATH_RELEASES}/${match.params.monthYear}`, label: monthYearFormatted + " Releases" }
    ];
    if (match.params.publisher) {
      crumbs.push({ link: `${PATH_RELEASES}/${match.params.monthYear}/publisher/${match.params.publisher}`, label: "Publisher: " + match.params.publisher.toLowerCase() });
      if (sectionInfo && sectionInfo.orderFormTitle) {
        crumbs.push({ link: `${PATH_RELEASES}/${match.params.monthYear}/publisher/${match.params.publisher}/${sectionInfo.orderFormId}`, label: sectionInfo.orderFormTitle.toLowerCase() });
      }
    } else if (sectionInfo && sectionInfo.orderFormTitle) {
      crumbs.push({ link: `${PATH_RELEASES}/${match.params.monthYear}/${sectionInfo.orderFormId}`, label: sectionInfo.orderFormTitle.toLowerCase() });
    }
    return (
      <div className="page book-in-release container-fluid px-3">
        <Breadcrumb crumbs={crumbs}>
          {this.state.bookCrumbTitle}
        </Breadcrumb>
        <p>
          <Button variant="outline-primary" as={Link} to={`${PATH_RELEASES}/${match.params.monthYear}`} size="sm">View all Sections</Button>
          <Button
            variant="outline-primary"
            as={Link}
            to={{pathname: `${PATH_RELEASES}/${match.params.monthYear}/${match.params.orderFormId}`, state: {isbn: match.params.isbn}}}
            size="sm"
            className="ml-2">View all Books</Button>
        </p>
        <div id="booktopstop" style={{width:"100%", height:"3px", position:"relative"}} />
        <div className={displayMode ? "display-wrapper px-3" : ""}>
            <Book key={match.params.isbn}
                isInRelease={true}
                goToBook={this.goToBook}
                prevBook={this.state.prevBook}
                nextBook={this.state.nextBook}
                detailsOpen={displayMode || this.state.detailsOpen}
                toggleDisplayMode={this.toggleDisplayMode}
                displayMode={displayMode}
                onLoad={this.onLoadBook}
            />
        </div>
      </div>
    );
  }
}

export default withRouter(BookInRelease);
