import { request } from '../functions/apiRequestWrapper';
import { saveAs } from 'file-saver';
import queryString from 'query-string';
import { logout } from './User';
import { getSavedOrders } from './SavedOrders';
import { DateTime } from 'luxon';
import _findIndex from 'lodash/findIndex';

const CART_LOADING = "CART/SET_LOADING";
const CART_LOADED = "CART/SET_LOADED";
const SET_ERROR = "CART/SET_ERROR";
const CART_ITEM_UPDATED = "CART/ITEM_UPDATED";
const FILE_DOWNLOADING = "CART/SET_DOWNLOADING";
const FILE_DOWNLOADED = "CART/SET_DOWNLOADED";
const LOGOUT = "CART/LOGOUT";

//*** GET CURRENT CART
export function getCurrentCart(auth) {
    return (dispatch, getState) => {
        dispatch({ type: CART_LOADING });
        let stored = localStorage.getItem("currentCart");
        //get existing cart or create a new one
        request(
            `${process.env.REACT_APP_API}/1/cart/current`,
            { method: 'POST' },
            auth
        ).then((data) => {
            if (stored) {
                console.log("check local stored cart against fetched cart");
                let localCart = JSON.parse(stored);
                if (DateTime.fromISO(localCart.when) > DateTime.fromISO(data.when) && localCart.items && localCart.items.length > 0) {
                    console.log("local cart is more recent and has items");
                    request(
                        `${process.env.REACT_APP_API}/1/cart/items`,
                        {
                            method: "POST",
                            body: JSON.stringify(localCart.items)
                        },
                        auth
                    ).then((updatedData) => {
                        dispatch({ type: CART_LOADED, payload: updatedData, offline: false });
                        localStorage.setItem("currentCart", JSON.stringify(updatedData));
                    });
                } else {
                    dispatch({ type: CART_LOADED, payload: data, offline: false });
                    localStorage.setItem("currentCart", JSON.stringify(data));
                }
            } else {
                dispatch({ type: CART_LOADED, payload: data, offline: false });
                localStorage.setItem("currentCart", JSON.stringify(data));
            }
        }).catch((error) => {
            if (error.status === 401) {
                return dispatch(logout());
            }
            console.error(error);
            // get or create an offline current cart
            let offlineCart = {};
            if (stored) {
                offlineCart = JSON.parse(stored);
            } else {
                let user = getState().user.data;
                offlineCart = {
                    totalAmount: 0,
                    items: [],
                    user: {
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email,
                        accountId: user.accountId
                    },
                    when: DateTime.now().toISO()
                }
                localStorage.setItem("currentCart", JSON.stringify(offlineCart));
            }
            console.log("creating offline cart", offlineCart);
            dispatch({ type: CART_LOADED, payload: offlineCart, offline: true });
            // let msg = "An error has occured";
            // if (error.body && error.body.message) {
            //     msg = error.body.message;
            // }
            // dispatch({ type: SET_ERROR, payload: msg });
        });
    }
}

//*** ADD TO CART
// helper - add item to items
const updateItems = (items, item, qty) => {
    let i = _findIndex(items, {productId: item.productId});
    qty = parseInt(qty);
    console.log("updateItems", items, item, qty, i);
    if (qty === 0) {
        // remove item
        if (i > -1) {
            items.splice(i, 1);
        }
    } else {
        // add item
        item.productCount = qty;
        item.amount = item.unitPrice * qty;
        if (i > -1) {
            items[i] = item;
        } else {
            items.push(item);
        }
    }
    return items;
}
export function addToCart(cartItem, qty) {
    return (dispatch, getState) => {
        console.log("addToCart");
        dispatch({ type: CART_LOADING });
        let reduxCart = getState().cart;
        if (reduxCart.offline) {
            console.log("last transaction was offline, attempt to fetch current cart");
            request(
                `${process.env.REACT_APP_API}/1/cart/current`,
                { method: 'POST' }
            ).then((data) => {
                console.log("compare when");
                if (DateTime.fromISO(reduxCart.currentCart.when) > DateTime.fromISO(data.when)) {
                    console.log("cart was updated offline");
                    let newItems = updateItems(reduxCart.currentCart.items, cartItem, qty);
                    request(
                        `${process.env.REACT_APP_API}/1/cart/items`,
                        {
                            method: "POST",
                            body: JSON.stringify(newItems)
                        }
                    ).then((updatedData) => {
                        dispatch({ type: CART_LOADED, payload: updatedData, offline: false });
                        localStorage.setItem("currentCart", JSON.stringify(updatedData));
                    });
                } else {
                    dispatch(updateOnlineCart(cartItem, qty));
                }
            }).catch((error) => {
                if (error.status === 401) {
                    return dispatch(logout());
                }
                console.error(error);
                dispatch(updateOfflineCart(cartItem, qty));
            });
        } else {
            dispatch(updateOnlineCart(cartItem, qty));
        }
    }
}
function updateOnlineCart(cartItem, qty) {
    return (dispatch, getState) => {
        console.log("updateOnlineCart");
        request(
            `${process.env.REACT_APP_API}/1/cart/item`,
            {
                method: 'PUT',
                body: JSON.stringify({
                    productId: cartItem.productId,
                    productCount: qty
                })
            }
        ).then((data) => {
            dispatch({ type: CART_ITEM_UPDATED, payload: data, offline: false });
            localStorage.setItem("currentCart", JSON.stringify(data));
        }).catch((error) => {
            if (error.status === 401) {
                return dispatch(logout());
            }
            console.error(error);
            dispatch(updateOfflineCart(cartItem, qty));
        });
    }
}
function updateOfflineCart(cartItem, qty) {
    return (dispatch, getState) => {
        console.log("updateOfflineCart");
        let reduxCart = getState().cart;
        let offlineCart = {...reduxCart.currentCart};
        offlineCart.when = DateTime.now().toISO();
        let newItems = updateItems(offlineCart.items, cartItem, qty);
        offlineCart.items = newItems;
        dispatch({ type: CART_ITEM_UPDATED, payload: offlineCart, offline: true });
        localStorage.setItem("currentCart", JSON.stringify(offlineCart));
    }
}

export function submitCart(payload, cb, errCb) {
    return (dispatch, getState) => {
        let q = queryString.stringify(payload);
        request(
            `${process.env.REACT_APP_API}/1/cart/order?${q}`,
            {
                method: "POST",
            }
        ).then((response) => {
            dispatch(getCurrentCart());
            if (typeof cb === 'function') { cb(response); }
        }).catch((error) => {
            console.error(error);
            if (typeof errCb === 'function') { errCb(error); }
        });
    }
}

//*** CLEAR CART
export function clearCart(cb) {
    return (dispatch, getState) => {

        dispatch({ type: CART_LOADING });

        request(
            `${process.env.REACT_APP_API}/1/cart/clear`,
            { method: 'POST' }

        ).then((data) => {
            dispatch({ type: CART_LOADED, payload: data, offline: false }); //return cartid from server
            localStorage.setItem("currentCart", JSON.stringify(data));
            if (typeof cb === "function") {
                cb();
            }

        }).catch((error) => {
            if (error.status === 401) {
                return dispatch(logout());
            }
            let user = getState().user.data;
            let offlineCart = {
                totalAmount: 0,
                items: [],
                user: {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    accountId: user.accountId
                },
                when: DateTime.now().toISO()
            };
            localStorage.setItem("currentCart", JSON.stringify(offlineCart));
            dispatch({ type: CART_LOADED, payload: offlineCart, offline: true });
        });
    }
}


//*** SAVE CART
export function saveCart(cb) {
    return (dispatch, getState) => {

        dispatch({ type: CART_LOADING });

        request(
            `${process.env.REACT_APP_API}/1/cart/save`,
            { method: 'POST' }
        ).then((data) => {
            dispatch({ type: CART_LOADED, payload: data, offline: false }); //return new empty cart
            localStorage.setItem("currentCart", JSON.stringify(data));
            dispatch(getSavedOrders(1));
            if (typeof cb === "function") { cb(); }
        }).catch((error) => {
            let offlineSaved = [];
            let stored = localStorage.getItem("savedCarts");
            if (stored) {
                offlineSaved = JSON.parse(stored);
            }
            let currentCart = getState().cart.currentCart;
            offlineSaved = [currentCart, ...offlineSaved];
            localStorage.setItem("savedCarts", JSON.stringify(offlineSaved));
            let user = getState().user.data;
            let offlineCart = {
                totalAmount: 0,
                items: [],
                user: {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    accountId: user.accountId
                },
                when: DateTime.now().toISO()
            }
            dispatch({ type: CART_LOADED, payload: offlineCart, offline: true });
            localStorage.setItem("currentCart", JSON.stringify(offlineCart));
            if (typeof cb === "function") { setTimeout(cb, 500)};
        });
    }
}

//*** EXPORT PDF
export function exportPdf() {
    return (dispatch, getState) => {
        dispatch({ type: FILE_DOWNLOADING });
        request(
            `${process.env.REACT_APP_API}/1/cart/pdf`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/pdf',
                    'Content-Type': 'application/pdf'
                }
            }

        ).then((data) => {
            var filename = getDownloadFilename('cart.pdf', data.headers);
            data.blob().then(x => saveAs(x, filename));
            dispatch({ type: FILE_DOWNLOADED });

        }).catch((error) => {
            console.error(error);
            let msg = "An error has occured";
            if (error.body && error.body.message) {
                msg = error.body.message;
            }
            dispatch({ type: SET_ERROR, payload: msg });
            dispatch({ type: FILE_DOWNLOADED });
        });
    }
}

//*** EXPORT CSV
export function exportCsv() {
    return (dispatch, getState) => {
        dispatch({ type: FILE_DOWNLOADING });
        request(
            `${process.env.REACT_APP_API}/1/cart/csv`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'text/csv',
                    'Content-Type': 'text/csv'
                }
            }

        ).then((data) => {
            var filename = getDownloadFilename('cart.csv', data.headers);
            data.blob().then(x => saveAs(x, filename));
            dispatch({ type: FILE_DOWNLOADED });

        }).catch((error) => {
            console.error(error);
            let msg = "An error has occured";
            if (error.body && error.body.message) {
                msg = error.body.message;
            }
            dispatch({ type: SET_ERROR, payload: msg });
            dispatch({ type: FILE_DOWNLOADED });
        });
    }
}

//*** LOAD SAVED ORDER TO CURRENT CART
export function appendSavedOrder(cartId, cb, errCb, auth) {

    return (dispatch, getState) => {

        dispatch({ type: CART_LOADING });

        //get existing cart or create a new one
        request(
            `${process.env.REACT_APP_API}/1/cart/load/${cartId}`,
            { method: 'POST' },
            auth
        ).then((data) => {
            dispatch({ type: CART_LOADED, payload: data }); //return cartid from server
            if (typeof cb === "function") { cb(); }
        }).catch((error) => {
            console.error(error);
            let msg = "An error has occured";
            if (error.body && error.body.message) {
                msg = error.body.message;
            }
            dispatch({ type: SET_ERROR, payload: msg });
            if (typeof errCb === "function") { errCb(msg); }
        });
    }
}

export function loadSavedOrder(cartId, cb, errCb, auth) {
    return (dispatch, getState) => {
        return dispatch(
            clearCart(() => { dispatch(appendSavedOrder(cartId, cb, errCb, auth)) })
        );
    }
}

//*** LOCAL METHODS

function getDownloadFilename(defaultName, headers) {
    var filename = defaultName;
    if(headers.get('content-disposition')!==undefined) {
        var splitContentDisposition = headers.get('content-disposition').split('=');
        if(splitContentDisposition.length > 1) {
            filename = splitContentDisposition[1];
        }
    }
    return filename;
}

export function cartLogout() {
    return {type: LOGOUT};
}

//*** REDUX STUFF

const defaultState = {
    isLoadingCart: false,
    isDownloadingFile: false,
    currentCart: null,
    error: null,
    offline: false
};

export function cartReducer(state = defaultState, action) {
    switch (action.type) {

        case CART_LOADING:
            return Object.assign({}, state, {
                isLoadingCart: true,
            });

        case CART_LOADED:
            return Object.assign({}, state, {
                isLoadingCart: false,
                currentCart: action.payload,
                offline: action.offline
            });

        case CART_ITEM_UPDATED:
            return Object.assign({}, state, {
                isLoadingCart: false,
                currentCart: action.payload,
                offline: action.offline
            });

        case FILE_DOWNLOADING:
            return Object.assign({}, state, {
                isDownloadingFile: true,
            });

        case FILE_DOWNLOADED:
            return Object.assign({}, state, {
                isDownloadingFile: false,
            });

        case SET_ERROR:
            return Object.assign({}, state, {
                error: action.payload,
                isLoadingCart: false,
            });

        case LOGOUT:
            return defaultState;

        default:
        return state;
    }
}
