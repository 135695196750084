import { request } from '../functions/apiRequestWrapper';
import { logout } from './User';

const SET_LIST = "SAVED_ORDERS/SET_LIST";
const CLEAR_LIST = "SAVED_ORDERS/CLEAR_LIST";
const SET_PAGE = "SAVED_ORDERS/SET_PAGE";
const SET_SORT = "SAVED_ORDERS/SET_SORT";
const SET_LOADING = "SAVED_ORDERS/SET_LOADING";
const SET_ERROR = "SAVED_ORDERS/SET_ERROR";
const LOGOUT = "SAVED_ORDERS/LOGOUT";

//*** GET SAVED ORDERS
export function getSavedOrders(_page, _orderBy, auth) {
    return (dispatch, getState) => {
        console.log("fetching saved orders");
        dispatch({ type: SET_LOADING, payload: true });
        const savedOrders = getState().savedOrders;
        let sortField = savedOrders.sortField;
        let currentPage = savedOrders.page;
        if (_page && typeof _page !== "undefined") {
            currentPage = _page;
            dispatch({type: SET_PAGE, payload: _page});
        }
        let skip = currentPage > 1 ? savedOrders.pageSize * (currentPage - 1) : 0;
        let orderBy = _orderBy || `${sortField} ${savedOrders.sortDir}`;
        let q = `?$top=${savedOrders.pageSize}&$skip=${skip}&$count=true&$orderby=${orderBy}`;
        request(
            `${process.env.REACT_APP_API}/1/cart/saved${q}`,
            { method: 'GET' },
            auth
        ).then((data) => {
            dispatch({ type: SET_LIST, payload: data.items, count: data.count });
        }).catch((error) => {
            console.error(error);
            if (error.status === 401) {
                return dispatch(logout());
            }
            let msg = "An error has occured";
            if (error.body && error.body.message) {
                msg = error.body.message;
            }
            dispatch({ type: SET_ERROR, payload: msg });
        });
    }
}

//*** DELETE A SAVED ORDER
export function deleteSavedOrder(cartId, cb, errCb) {
    return (dispatch, getState) => {
        dispatch({ type: SET_LOADING, payload: true });
        request(
            `${process.env.REACT_APP_API}/1/cart/${cartId}`,
            { method: 'DELETE' }
        ).then((data) => {
            const savedOrders = getState().savedOrders;
            let maxPages = Math.ceil((savedOrders.count-1) / savedOrders.pageSize);
            let page = undefined;
            if (maxPages < savedOrders.page) {
                page = maxPages;
            }
            let newAuth = data.newAuth || false;
            dispatch(getSavedOrders(page, undefined, newAuth));
            if (typeof cb === "function") { cb(); }
        }).catch((error) => {
            console.error(error);
            if (error.status === 401) {
                return dispatch(logout());
            }
            let msg = "An error has occured";
            if (error.body && error.body.message) {
                msg = error.body.message;
            }
            dispatch({ type: SET_ERROR, payload: msg });
            if (typeof errCb === "function") { errCb(msg); }
        });
    }
}

export function syncOfflineOrder(cart, cb, errCb) {
    return (dispatch, getState) => {
        dispatch({ type: SET_LOADING, payload: true });
        request(
            `${process.env.REACT_APP_API}/1/cart/items/save`,
            {
                method: 'POST',
                body: JSON.stringify(cart.items)
            }
        ).then((data) => {
            let newAuth = data.newAuth || false;
            dispatch(getSavedOrders(1, undefined, newAuth));
            if (typeof cb === "function") { cb(); }
        }).catch((error) => {
            console.error(error);
            if (error.status === 401) {
                return dispatch(logout());
            }
            let msg = "An error has occured";
            if (error.body && error.body.message) {
                msg = error.body.message;
            }
            dispatch({ type: SET_ERROR, payload: msg });
            if (typeof errCb === "function") { errCb(msg); }
        });
    }
}

export function setSort(field, dir) {
    return (dispatch, getState) => {
        dispatch({type: SET_SORT, field, dir});
        dispatch(getSavedOrders(undefined, `${field} ${dir}`));
    }
}

export function clearList() {
    return {type: CLEAR_LIST};
}

export function savedOrdersLogout() {
    return {type: LOGOUT};
}

const defaultState = {
    list: [],
    count: 0,
    page: 1,
    pageSize: 12,
    sortField: "when", // "totalAmount"
    sortDir: "desc", // "asc"
    isLoading: false,
    error: null,
};

export function savedOrdersReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_LIST:
        return Object.assign({}, state, {
            list: action.payload,
            count: action.count,
            isLoading: false,
            error: null
        });
        case CLEAR_LIST:
        return Object.assign({}, state, {
            list: [],
            count: 0,
            page: 1,
            error: null
        });
        case SET_PAGE:
        return Object.assign({}, state, {
            page: action.payload
        });
        case SET_SORT:
        return Object.assign({}, state, {
            sortField: action.field || state.sortField,
            sortDir: action.dir || state.sortDir
        });
        case SET_LOADING:
        return Object.assign({}, state, {
            isLoading: action.payload
        });
        case SET_ERROR:
        return Object.assign({}, state, {
            error: action.payload,
            list: [],
            isLoading: false
        });
        case LOGOUT:
        return defaultState;

        default:
        return state;
    }
}
