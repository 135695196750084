import { request } from '../functions/apiRequestWrapper';

export function updateServiceWorker() {
  return (dispatch, getState) => {
    let sw = getState().ui.sw;
    let waiting = sw.waiting;
    if (waiting) {
      waiting.postMessage({type: 'SKIP_WAITING'});
      waiting.addEventListener('statechange', e => {
        if (e.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  }
}

export function checkSiteStatus() {
  return (dispatch, getState) => {
    request(
      `${process.env.REACT_APP_API}/1/service/websitestatus`
    ).then((data) => {
      // console.log(data);
      if (data !== "online") {
        dispatch({ type: "UI/SET_MAINTENANCE", payload: true });
      }
    }).catch((error) => {
      console.error(error);
    });
  }
}

export function uiReducer(state = {hasUpdate: false, maintenance: false}, action) {
  switch(action.type) {
    case "UI/SW_UPDATE":
    return Object.assign({}, state, {
      sw: action.payload,
      hasUpdate: true
    });
    case "UI/SET_MAINTENANCE":
    return Object.assign({}, state, {
      maintenance: action.payload
    });
    default:
    return state;
  }
}
