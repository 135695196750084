import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import BooksNav from '../../components/BooksNav';
import Breadcrumb from '../../components/Breadcrumb';
import ViewCart from './ViewCart';
import SavedOrders from './SavedOrders';
import { PATH_CART, PATH_SAVED_ORDERS, PATH_CHECKOUT } from '../../constants';
import Checkout from './Checkout';

class Cart extends React.Component {

    componentDidMount() {
        setTimeout(() => {window.scrollTo(0, 0);}, 100);
    }

    render() {
        return (
            <div className="page container px-3 px-md-5">
                <Switch>

                    <Route path={PATH_CART}>
                        <Breadcrumb>Basket</Breadcrumb>
                        <h1 className="mb-5">Basket</h1>
                        <BooksNav actions={[{ to: PATH_CART, label: "Current Basket" }, { to: PATH_SAVED_ORDERS, label: "Saved Orders" }]} activeId={this.props.match.path} />
                        <ViewCart history={this.props.history} />
                    </Route>

                    <Route path={PATH_CHECKOUT}>
                        <Breadcrumb>Checkout</Breadcrumb>
                        <h1 className="mb-5">Checkout</h1>
                        <Checkout />
                    </Route>

                    <Route path={PATH_SAVED_ORDERS}>
                        <Breadcrumb>Saved Orders</Breadcrumb>
                        <h1 className="mb-5">Saved Orders</h1>
                        <BooksNav actions={[{ to: PATH_CART, label: "Current Basket" }, { to: PATH_SAVED_ORDERS, label: "Saved Orders" }]} activeId={this.props.match.path} />
                        <SavedOrders history={this.props.history} />
                    </Route>

                </Switch>
            </div>
        );
    }
}

export default withRouter(Cart);
