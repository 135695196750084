export const trimFormValues = (values) => {
    let keys = Object.keys(values);
    for (let i=0; i<keys.length; i++) {
        let k = keys[i];
        let v = values[k];
        if (typeof v === "string") {
            values[k] = v.trim();
        }
    }
    return values;
}

export const validateEmail = (email) => {
    // https://stackoverflow.com/a/9204568
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

// convert number to currency string with comma
// returns a String
export const formatDollar = (num) => {
    if (typeof num === 'undefined' || num === null || num === "") { return " "; }
    if (typeof num === 'string' && num.indexOf('$') === 0) { return num; }
    var _num = parseFloat(num);
    var p = _num.toFixed(2).split(".");
    var s = _num < 0 ? "-$" : "$";
    var n = p[0].split("").reverse().reduce(function(acc, _num, i, orig) {
        return  _num === "-" ? acc : _num + (i && !(i % 3) ? "," : "") + acc;
    }, "") + "." + p[1];
    return s+n;
};

// convert a currency string to number by removing non-numeric chars
// returns a Number
export const unFormatDollar = (currency) => {
    return Number(currency.replace(/[^0-9.-]+/g,""));
}

// count total number of products in a cart
export const countProductsReducer = (accumulator, currentValue) => {
    if (currentValue.productCount) {
        return accumulator + currentValue.productCount;
    }
    if (currentValue.lineItems) {
        return accumulator + currentValue.lineItems;
    }
    return accumulator;
};
export const getTotalItems = (items) => {
    return items.reduce(countProductsReducer, 0);
}

// helper function to format author names
// converts "Lastname, Firstname" to "Firstname Lastname"
export const getAuthorNameFL = (name) => {
    if (name === ".") { return ""; }
    let nameFL = "";
    if (name.indexOf("/") > -1) {
        // multiple authors
        let authors = name.split("/");
        let authorsFLArray = [];
        for (let a=0; a<authors.length; a++) {
            let aFL = ""
            let authorSplit = authors[a].split(", ");
            if (authorSplit.length > 1) {
                aFL += `${authorSplit[1]} `;
            }
            aFL += `${authorSplit[0]}`;
            authorsFLArray.push(aFL);
        }
        nameFL = authorsFLArray.join(" / ");
    } else {
        // single author
        let authorSplit = name.split(", ");
        if (authorSplit.length > 1) {
            nameFL += `${authorSplit[1]} `;
        }
        nameFL += `${authorSplit[0]}`;
    }
    return nameFL;
}
