import React from 'react';
import { withRouter } from 'react-router';
import { request } from '../functions/apiRequestWrapper';
import BookListItem from '../components/BookListItem';
import SectionListPublisherItem from '../components/SectionListPublisherItem';
import Loader from '../components/Loader';
import Breadcrumb from '../components/Breadcrumb';
import { PATH_RELEASES } from '../constants';

import { connect } from 'react-redux';
import { logout } from '../redux/User';
const mapStateToProps = state => {
  return {}
}
const mapDispatchToProps = dispatch => {
  return {
    logout: () => { dispatch(logout()); }
  }
}

class ReleaseByPublisher extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      releaseData: null,
      sectionInfo: {},
      sectionProducts: [],
      isLoadingRelease: false,
      isLoadingSection: false
    }
  }

  componentDidMount() {
    const { match } = this.props;
    this.getRelease();
    if (match.params.publisher && match.params.monthYear && match.params.orderFormId) {
      this.getReleaseSection();
    }
    setTimeout(() => {window.scrollTo(0, 0);}, 100);
  }

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (
      (match.params.publisher && prevProps.match.params.publisher !== match.params.publisher) ||
      (match.params.orderFormId && prevProps.match.params.orderFormId !== match.params.orderFormId) ||
      (match.params.monthYear && prevProps.match.params.monthYear !== match.params.monthYear)
    ) {
      if (match.params.publisher && match.params.monthYear && match.params.orderFormId) {
        this.getReleaseSection(match.params.monthYear, match.params.publisher, match.params.orderformId);
      }
    }
  }

  getRelease = (_monthYear, _publisher) => {
    const { match } = this.props;
    let monthYear = _monthYear || match.params.monthYear;
    let publisher = _publisher || match.params.publisher;

    this.setState({ isLoadingRelease: true });
    return request(
      `${process.env.REACT_APP_API}/1/releases/${monthYear}/publishers/${publisher}`
    ).then((data) => {
      this.setState({ releaseData: data, isLoadingRelease: false });
    }).catch((error) => {
      if (error.status === 401) { this.props.logout(); }
      console.error(error);
      this.setState({ isLoadingRelease: false });
    });
  }

  getReleaseSection = (_monthYear, _publisher, _orderFormId) => {
    const { match } = this.props;
    let monthYear = _monthYear || match.params.monthYear;
    let publisher = _publisher || match.params.publisher;
    let orderFormId = _orderFormId || match.params.orderFormId;
    if (!publisher || typeof publisher === "undefined") {
      return;
    }

    this.setState({ isLoadingSection: true });
    request(
      `${process.env.REACT_APP_API}/1/releases/${monthYear}/${orderFormId}/publishers/${publisher}/products`

    ).then((data) => {
      this.setState({
          sectionProducts: data.products,
          sectionInfo: data.links,
        isLoadingSection: false
      });
      // console.log(data);

    }).catch((error) => {
      console.error(error);
      this.setState({ isLoadingSection: false });
    });
  }

  render() {
    const { match } = this.props;
    const { releaseData, sectionProducts, sectionInfo, isLoadingSection, isLoadingRelease } = this.state;
    const { monthYear, publisher, orderFormId } = match.params;
    const releaseTitle = releaseData && releaseData.catalogue && releaseData.catalogue.toLowerCase() + " releases";
    const publisherTitle = `Publisher: ${publisher.toLowerCase()}`;
    let crumbs = [{ link: PATH_RELEASES, label: "Releases" }, { link: `${PATH_RELEASES}/${monthYear}`, label: releaseTitle }];
    let currentCrumb = publisherTitle;
    if (orderFormId) {
      crumbs.push({ link: `${PATH_RELEASES}/${monthYear}/publisher/${publisher}`, label: publisherTitle });
      currentCrumb = sectionInfo.orderFormTitle;
    }
    return (
      <div className="page releases container px-3 px-md-5">
        <Breadcrumb crumbs={crumbs}>{currentCrumb}</Breadcrumb>
        <div className="row mb-4">
          <div className="col-12 col-md-auto">
            <h1 className="text-capitalize mb-5">{releaseTitle}</h1>
            <h2 className="text-capitalize">{publisherTitle}</h2>
          </div>
        </div>

        {releaseData && releaseData.sections && !orderFormId && <div className="list releases">
          <div className="row product-row">
            {releaseData.sections.map(section => {
              return (
                <div key={section.orderFormId} className="col-6 col-sm-4 col-md-3">
                  <SectionListPublisherItem monthYear={monthYear} publisher={publisher} className="mb-5" {...section} />
                </div>
              );
            })}
          </div>
        </div>}

        <br />

        {(isLoadingSection || isLoadingRelease) && <Loader type="placeholder" />}
        
        {orderFormId && sectionProducts && !isLoadingSection &&
          <div className="row mb-4">
            <div className="col-12 col-md-auto">
              <h3 className="mb-2">{sectionInfo.orderFormTitle}</h3>
            </div>
          </div>
        }

        <div className="row product-row">
          {orderFormId && sectionProducts && !isLoadingSection && sectionProducts.map((product, i) => {
            return (
              <div key={product.isbn} className="col-6 col-sm-4 col-md-3">
                <BookListItem monthYear={monthYear} orderFormId={orderFormId} publisher={publisher} className="mb-5" {...product} />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReleaseByPublisher));
