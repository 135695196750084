import React from 'react';
import Loader from '../../../components/Loader';
import Button from 'react-bootstrap/Button';
import { request } from '../../../functions/apiRequestWrapper';

import {
  PATH_LOGIN,
  PATH_REQUEST_CONFIRMAION
} from '../../../constants';

/*
 * This form will be reached via an email link
 * which will contain 'id' and 'token' in the query string
 */

class EmailConfirmation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: true,
      submitStatus: null,
      serverError: null
    }
  }

  componentDidMount() {
    const {id, token} = this.props;
    if (!id || !token) {
      this.setState({
        isSubmitting: false,
        submitStatus: 400,
        serverError: "The link is invalid."
      });
    } else {
      request(
        `${process.env.REACT_APP_API}/1/account/email/confirm`,
        {
          method: 'POST',
          body: JSON.stringify({id, token})
        }
      ).then((response) => {
        this.setState({submitStatus: 200, isSubmitting: false});
      }).catch((error) => {
        this.setState({
          submitStatus: error.status || 400,
          isSubmitting: false,
          serverError: "Your email could not be confirmed. This may be because the link has expired."
        });
      });
    }
  }

  render() {
    const {isSubmitting, submitStatus, serverError} = this.state;
    if (isSubmitting) {
      return (
        <div className="p-5 position-relative">
          <h3 className="mb-4">Email Confirmation</h3>
          <Loader type="placeholder">Confirming your email</Loader>
        </div>
      );
    } else if (submitStatus === 200) {
      return (
        <div className="p-5 position-relative">
          <h3 className="mb-4">Welcome to HarperHQ</h3>
          <p className="mb-3">
            Your email is now confirmed.
          </p>
          <Button variant="primary" onClick={() => { this.props.goTo(PATH_LOGIN, 1); }}>Login</Button>
        </div>
      );
    } else if (submitStatus !== null && submitStatus > 200) {
      return (
        <div className="p-5 position-relative">
          <h3 className="mb-4">Email Confirmation</h3>
          <p className="server-error text-danger mb-3">{serverError}</p>
          <Button variant="primary" onClick={() => { this.props.goTo(PATH_REQUEST_CONFIRMAION, 1); }}>Request a new confirmation link</Button>
        </div>
      );
    }
    return null;
  }
}

export default EmailConfirmation;
