import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { request } from '../functions/apiRequestWrapper';
import Breadcrumb from '../components/Breadcrumb';
import Banner from '../components/Banner';
import Loader from '../components/Loader';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import _findIndex from 'lodash/findIndex';
import _find from 'lodash/find';
import { PATH_HELP } from '../constants';

import { connect } from 'react-redux';
import { logout } from '../redux/User';
const mapDispatchToProps = dispatch => {
    return {
        logout: () => { dispatch(logout()); }
    }
}

class Help extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            error: null,
            page: {title: "Help"},
            questions: false,
            categories: [],
            activeQuestion: null
        };
    }

    componentDidMount() {
        setTimeout(() => {window.scrollTo(0, 0);}, 100);
        const {match, history} = this.props;
        request(
            `${process.env.REACT_APP_API}/1/cms/contentpage/help`
        ).then((data) => {
            // console.log(data);
            this.setState({page: data});

            request(
                `${process.env.REACT_APP_API}/1/cms/faqs`
            ).then((data) => {
                // console.log(data);
                // filter empty categories
                let cats = [];
                if (data.categories && data.categories.length > 0 && data.questions) {
                    for (let i=0; i<data.categories.length; i++) {
                        let cat = data.categories[i];
                        let idx = _findIndex(data.questions, {category: cat.label});
                        if (idx > -1) {
                            cats.push(cat);
                        }
                    }
                }
                this.setState({categories: cats, questions: data.questions, isLoading: false}, () => {
                    if (match.params.category && _findIndex(cats, {slug: match.params.category}) === -1) {
                        history.push(PATH_HELP);
                    }
                });
            }).catch((error) => {
                if (error.status === 401) {
                    this.props.logout();
                } else {
                    console.error(error);
                    let msg = "An error has occured";
                    if (error.body && error.body.message) {
                        msg = error.body.message;
                    }
                    this.setState({error: msg, isLoading: false});
                }
            });

        }).catch((error) => {
            if (error.status === 401) {
                this.props.logout();
            } else {
                console.error(error);
                let msg = "An error has occured";
                if (error.body && error.body.message) {
                    msg = error.body.message;
                }
                this.setState({error: msg});
            }
        });
    }

    toggleActiveQuestion = (id) => {
        // track active accordion panel to apply styles
        if (this.state.activeQuestion === id) {
            this.setState({activeQuestion: null});
        } else {
            this.setState({activeQuestion: id});
        }
    }

    render() {
        const {match} = this.props;
        const {page, categories, questions, activeQuestion} = this.state;
        const activeCategorySlug = match.params.category;
        let activeCategoryLabel = null;
        if (activeCategorySlug && categories.length > 1) {
            let activeCategory = _find(categories, {slug: activeCategorySlug}) || {};
            activeCategoryLabel = activeCategory.label
        }
        return (
            <div className="page help container px-3 px-md-5">
                <Breadcrumb>Help</Breadcrumb>
                <Banner imageUrl={page && page.imageUrl ? page.imageUrl : false}><h1><b>{page.title}</b></h1></Banner>
                {page && page.bodyContent && <div dangerouslySetInnerHTML={{__html: page.bodyContent}} className="mb-5" />}

                {categories && categories.length > 1 &&
                    <div className="faq-categories mb-5 mx-md-5">
                        <Button key={`all`}
                            as={Link}
                            to={`${PATH_HELP}`}
                            variant={(activeCategorySlug === undefined || activeCategorySlug === null) ? "primary" : "outline-primary"}
                            className="mr-3">
                            All
                        </Button>

                        {categories.map((cat, i) => {
                            return (
                                <Button key={`catlink${i}`}
                                    as={Link}
                                    to={`${PATH_HELP}/${cat.slug}`}
                                    variant={activeCategorySlug === cat.slug ? "primary" : "outline-primary"}
                                    className="mr-3">
                                        {cat.label}
                                </Button>
                            );
                        })}
                    </div>
                }

                {questions && <Accordion className="faq mb-5 mx-md-5">
                    {questions.map((q, index) => {
                        if (activeCategoryLabel === null || q.category === activeCategoryLabel) {
                            return (
                                <Card key={`q_${q.id}`} className={classnames({"active": activeQuestion === q.id})}>
                                    <Accordion.Toggle as={Card.Header} eventKey={`${q.id}`} onClick={() => { this.toggleActiveQuestion(q.id); }}>
                                        {q.question}
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={`${q.id}`}>
                                        <Card.Body>
                                            <div dangerouslySetInnerHTML={{__html: q.answer}} />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            );
                        }
                        return null;
                    })}
                </Accordion>}

                {this.state.isLoading && <Loader type="placeholder" />}
            </div>
        );
    }
}

const routerHelp = withRouter(Help);
export default connect(undefined, mapDispatchToProps)(routerHelp);
