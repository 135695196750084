import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _debounce from 'lodash/debounce';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import './pagination.scss';

class Pagination extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        stepInput: ""
    };
    this.stepsRef = {};
    this.containerRef = React.createRef();
    this.indicatorRef = React.createRef();
    this._debouncedUpdate = _debounce(this.updateIndicatorPosition, 400);
  }

  componentDidMount() {
    this.updateIndicatorPosition(this.props.activeIndex);
    window.addEventListener("resize", this._debouncedUpdate, false);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.activeIndex !== this.props.activeIndex ||
      prevProps.totalSteps !== this.props.totalSteps ||
      prevProps.maxVisibleSteps !== this.props.maxVisibleSteps
    ) {
      this.updateIndicatorPosition(this.props.activeIndex);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this._debouncedUpdate, false);
  }

  handleClickStep = (e, idx) => {
    e.preventDefault();
    this.props.onChange(idx);
  }

  handleClickPrev = (e) => {
    e.preventDefault();
    if (this.props.activeIndex > 0) {
      this.props.onChange(this.props.activeIndex - 1);
    }
  }

  handleClickNext = (e) => {
    e.preventDefault();
    if (this.props.activeIndex < this.props.totalSteps - 1) {
      this.props.onChange(this.props.activeIndex + 1);
    }
  }

  getVisibleSteps = () => {
    const {totalSteps, activeIndex, maxVisibleSteps} = this.props;
    let visibles = [];
    if (maxVisibleSteps > 5 && totalSteps > maxVisibleSteps) {
      // current, prev and next steps
      if (activeIndex < 2) { visibles = [0,1,2]; }
      else if (activeIndex > totalSteps - 3) { visibles = [totalSteps - 3, totalSteps - 2, totalSteps - 1]; }
      else { visibles = [activeIndex - 1, activeIndex, activeIndex + 1]; }
      // first step
      if (visibles[0] !== 0) {
        if (visibles[0] > 1) {
          visibles.unshift(0, 'ellipsis');
        } else {
          visibles.unshift(0);
        }
      }
      // last step
      if (activeIndex < totalSteps - 3) { visibles.push('ellipsis', totalSteps - 1); }
      else if (activeIndex < totalSteps - 2) { visibles.push(totalSteps - 1); }
    } else {
      // return all steps
      for (let i=0; i<totalSteps; i++) {
        visibles.push(i);
      }
    }
    return visibles;
  }

  updateIndicatorPosition = (i) => {
    if (isNaN(i)) {
      i = this.props.activeIndex;
    }
    let rectContainer = this.containerRef.current.getBoundingClientRect();
    let rectTarget = this.stepsRef[`${i}`].getBoundingClientRect();
    let targetWidth = this.stepsRef[`${i}`].offsetWidth;
    let indicatorLeft = (rectTarget.left - rectContainer.left) + (targetWidth / 2);
    this.indicatorRef.current.style.left = `${indicatorLeft}px`;
    // this.indicatorRef.current.style.width = `${this.stepsRef[`${i}`].offsetWidth}px`;
  }

  render() {
    const {totalSteps, maxVisibleSteps, activeIndex} = this.props;
    const visibleStepIndexes = this.getVisibleSteps();
    return(
      <nav ref={this.containerRef} className="hqpagination">
        <ul className="hqpagination__items">
          <li className="hqpagination__item">
            <button className={classnames("hqpagination__prev", {"disabled": activeIndex === 0})} onClick={this.handleClickPrev}>
              <ChevronLeft size={16} />
              <span className="sr-only">Previous</span>
            </button>
          </li>
          {visibleStepIndexes.map((stepIdx, i) => {
            return (
              <li key={`step_${i}`} className="hqpagination__item">
                {stepIdx === 'ellipsis' && <span className="hqpagination__ellipsis">…</span>}
                {typeof stepIdx === 'number' &&
                  <button
                    ref={(el) => this.stepsRef[`${stepIdx}`] = (el)}
                    className={classnames("hqpagination__link", {"active": stepIdx === activeIndex})}
                    onClick={(e) => {this.handleClickStep(e, stepIdx);}}
                  >
                    {stepIdx + 1}
                  </button>
                }
              </li>
            );
          })}
          {(maxVisibleSteps > 5 && totalSteps > maxVisibleSteps) &&
              <li className="hqpagination__item">
                  <input
                    type="number"
                    min="0"
                    value={this.state.stepInput}
                        onChange={(e) => {
                            this.setState({
                                stepInput: e.target.value === '' ? '' :
                                    Math.max(1, 
                                        Math.min(e.target.value, visibleStepIndexes[visibleStepIndexes.length - 1] + 1)
                                    )
                            });
                        }}
                    onKeyDown={(e) => { if (e.keyCode === 13) {this.handleClickStep(e, parseInt(e.target.value) - 1);} }}
                    />
              </li>
          }
          <li className="hqpagination__item">
            <button className={classnames("hqpagination__next", {"disabled": activeIndex + 1 === totalSteps})} onClick={this.handleClickNext}>
              <ChevronRight size={16} />
              <span className="sr-only">Next</span>
            </button>
          </li>
        </ul>
        <span ref={this.indicatorRef} className="hqpagination__indicator"></span>
      </nav>
    );
  }

}

Pagination.propTypes = {
  totalSteps: PropTypes.number,
  activeIndex: PropTypes.number,
  maxVisibleSteps: PropTypes.number, // should be at least 5
  onChange: PropTypes.func
};

Pagination.defaultProps = {
  maxVisibleSteps: 7,
  onChange: () => {}
}

export default Pagination;
