import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import FormField from '../../../components/FormField';
import Loader from '../../../components/Loader';
import Button from 'react-bootstrap/Button';
import { request } from '../../../functions/apiRequestWrapper';

import {
  PATH_LOGIN,
  PATH_REQUEST_CONFIRMAION,
  PATH_FORGOT_PASS
} from '../../../constants';

/*
 * This form will be reached via an email link
 * which will contain 'id' and 'token' in the query string
 */

class ResetPasswordForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      isSubmitting: false,
      submitStatus: null,
      serverError: false
    }
  }

  submitForm = (values, formikBag) => {
    this.setState({isSubmitting: true});
    var _values = Object.assign({}, values, {id: this.props.id, token: this.props.token});
    request(
      `${process.env.REACT_APP_API}/1/account/password/reset`,
      {
        method: 'POST',
        body: JSON.stringify(_values)
      }
    ).then((response) => {
      this.setState({step: 2, submitStatus: 200, isSubmitting: false});
    }).catch((error) => {
      this.setState({step: 2, submitStatus: error.status, isSubmitting: false});
      if (error.body && error.body.modelState) {
        formikBag.setErrors(error.body.modelState);
      }
    });
  }

  validateForm = (values) => {
      let errors = {};
      if (!values.newPassword || values.newPassword === "") {
          errors.newPassword = "Please enter a new password";
      } else {
          if (values.newPassword.length < 6) {
              errors.newPassword = "Password should be at least 6 characters";
          }
      }
      if (values.confirmPassword.length < 6) {
          errors.confirmPassword = "Password should be at least 6 characters";
      } else {
          if (values.newPassword && values.newPassword !== "" && (!values.confirmPassword || values.newPassword !== values.confirmPassword)) {
              errors.confirmPassword = "Password must match";
          }
      }
      return errors;
  }

  render() {
    const { step, submitStatus } = this.state;
    return (
      <Formik validate={this.validateForm} onSubmit={this.submitForm} initialValues={{newPassword: "", confirmPassword: ""}}>
        {(formikBag) => (
          <Form className="p-5 position-relative">

            {this.state.isSubmitting && <Loader />}

            <h3 className="mb-4">Reset your password</h3>

            {step === 1 && <>
              <FormField id="newPassword" type="password" label="New password" autoComplete="new-password" formikBag={formikBag} hasValidation />
              <FormField id="confirmPassword" type="password" label="Confirm password" autoComplete="new-password" formikBag={formikBag} hasValidation />
              <Button type="submit" variant="primary">Reset Password</Button>
              {this.state.serverError && <p className="server-error py-3">{this.state.serverError}</p>}
            </>}

            {step === 2 && submitStatus === 200 && <>
              <p className="">Your password has been reset.</p>
              <Button variant="primary" onClick={() => { this.props.goTo(PATH_LOGIN, 1); }}>Login</Button>
            </>}

            {step === 2 && (submitStatus === 400 || submitStatus === 404) && <>
              <p className="">The link has either expired or is invalid.</p>
              <Button variant="primary" onClick={() => { this.props.goTo(PATH_FORGOT_PASS, 1); }}>Get a new link</Button>
            </>}

            {step === 2 && submitStatus === 403 && <>
              <p className="">Your password has been reset, but you have not yet confirmed your email.</p>
              <Button buttonType="primary" onClick={() => { this.props.goTo(PATH_REQUEST_CONFIRMAION, 1); }}>Request Email confirmation</Button>
            </>}

          </Form>
        )}
      </Formik>
    );
  }
}

ResetPasswordForm.propTypes = {
  goTo: PropTypes.func,
  id: PropTypes.string, // from query string
  token: PropTypes.string // from query string
}

export default ResetPasswordForm;
