import React from 'react';
import Loader from '../../components/Loader';
import Pagination from '../../components/Pagination';
import SavedOrderListItem from '../../components/SavedOrderListItem';
import Button from 'react-bootstrap/Button';
import { ChevronUp, ChevronDown } from 'react-bootstrap-icons';
import { PATH_CART } from '../../constants';

import { connect } from 'react-redux';
import { getSavedOrders, deleteSavedOrder, syncOfflineOrder, setSort } from '../../redux/SavedOrders';
import { appendSavedOrder, loadSavedOrder } from '../../redux/Cart';
const mapStateToProps = state => {
    return {
        list: state.savedOrders.list,
        count: state.savedOrders.count,
        page: state.savedOrders.page,
        pageSize: state.savedOrders.pageSize,
        sortField: state.savedOrders.sortField,
        sortDir: state.savedOrders.sortDir,
        isLoading: state.savedOrders.isLoading,
        error: state.savedOrders.error,
    };
}
const mapDispatchToProps = dispatch => {
    return {
        getSavedOrders: (p) => { dispatch(getSavedOrders(p)); },
        deleteSavedOrder: (cartId, cb, errCb) => { dispatch(deleteSavedOrder(cartId, cb, errCb)); },
        appendSavedOrder: (cartId, cb, errCb) => { dispatch(appendSavedOrder(cartId, cb, errCb)); },
        loadSavedOrder: (cartId, cb, errCb) => { dispatch(loadSavedOrder(cartId, cb, errCb)); },
        syncOfflineOrder: (cart, cb, errCb) => { dispatch(syncOfflineOrder(cart, cb, errCb)); },
        setSort: (f,d) => { dispatch(setSort(f,d)); },
    };
}

class SavedOrders extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            offlineSaved: false
        };
    }

    componentDidMount() {
        if (!this.props.list || this.props.list.length === 0) {
            this.props.getSavedOrders();
        }
        let stored = localStorage.getItem("savedCarts");
        if (stored) {
            this.setState({offlineSaved: JSON.parse(stored)});
        }
    }

    handlePaginationChange = (idx) => {
        setTimeout(() => {window.scrollTo(0, 0);}, 800);
        this.props.getSavedOrders(idx + 1);
    }

    syncOfflineOrder = (idx, cb, errCb) => {
        const { offlineSaved } = this.state;
        this.props.syncOfflineOrder(
            offlineSaved[idx],
            () => {
                this.deleteOfflineOrder(idx);
                cb();
            },
            errCb
        );
    }

    deleteOfflineOrder = (idx) => {
        const { offlineSaved } = this.state;
        let a = [...offlineSaved];
        a.splice(idx, 1);
        this.setState({offlineSaved: a});
        localStorage.setItem("savedCarts", JSON.stringify(a));
        // console.log("deleteOfflineOrder", a);
    }

    render() {
        const { list, isLoading, page, pageSize, sortField, sortDir, count, error } = this.props;
        const { offlineSaved } = this.state;
        return (
            <div className="page saved-orders">

                {offlineSaved && offlineSaved.length > 0 && <div className="list order-history mb-2">
                    <h4 className="mb2">Offline Orders</h4>
                    <div className="row">
                        {offlineSaved.map((cart, i) =>
                            <SavedOrderListItem key={`offline${i}`}
                                cart={cart}
                                offline={true}
                                idx={i}
                                syncOfflineOrder={this.syncOfflineOrder}
                                deleteSavedOrder={this.deleteOfflineOrder}
                            />
                        )}
                    </div>
                </div>}

                {offlineSaved && offlineSaved.length > 0 && <h4 className="mb2">Online Orders</h4>}
                <div className="row mb-5">
                    <div className="col-12 col-sm-auto">Page {page} of {count} results</div>
                    <div className="col text-sm-right sorting">
                        <span className="mx-1">Sort by</span>
                        <Button size="sm"
                            variant={sortField === "when" ? "primary" : "secondary"}
                            onClick={() => {this.props.setSort("when", sortDir)}}>
                            Date
                        </Button>
                        <Button size="sm"
                            variant={sortField === "totalAmount" ? "primary" : "secondary"}
                            onClick={() => {this.props.setSort("totalAmount", sortDir)}}>
                            Amount
                        </Button>
                        <div className="divider" />
                        {sortDir === "asc" && <Button size="sm"
                            variant="secondary"
                            onClick={() => {this.props.setSort(sortField, "desc")}}>
                            Ascending <ChevronDown size={16} />
                        </Button>}
                        {sortDir === "desc" && <Button size="sm"
                            variant="secondary"
                            onClick={() => {this.props.setSort(sortField, "asc")}}>
                            Descending <ChevronUp size={16} />
                        </Button>}
                    </div>
                </div>

                {isLoading && <Loader type={list && list.length > 0 ? "overlay" : "placeholder"} />}
                {(!isLoading && error) && <p className="alert alert-danger my-5">{error}</p>}

                {(!error && list && list.length > 0) &&
                    <div className="list order-history">
                        <div className="row">
                            {list && list.map((cart, i) =>
                                <SavedOrderListItem key={cart.id}
                                    cart={cart}
                                    deleteSavedOrder={this.props.deleteSavedOrder}
                                    appendSavedOrder={this.props.appendSavedOrder}
                                    loadSavedOrder={this.props.loadSavedOrder}
                                    goToCart={() => { this.props.history.push(PATH_CART); }}
                                />
                            )}
                        </div>
                    </div>
                }

                {(count > pageSize) && <div className="mb-5">
                    <Pagination
                        totalSteps={Math.ceil(count / pageSize)}
                        activeIndex={page - 1}
                        onChange={this.handlePaginationChange}
                    />
                </div>}

            </div>
        );
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SavedOrders);
