const Banner = ({imageUrl, children}) => {
    return (
        <div className="page-banner mb-5">
            <div className="page-banner__text">
                {children}
            </div>
            {imageUrl && <div className="page-banner__img mt-4"  style={{backgroundImage: `url("${imageUrl}")`}} />}
        </div>
    );
};

export default Banner;
