import React from 'react';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import cookie from './functions/cookie';
import Loader from './components/Loader';
import Header from './components/Header';
import Footer from './components/Footer';

import Login from './pages/Login';
import Home from './pages/Home';
import Account from './pages/Account';
import Offers from './pages/Offers';
import Offer from './pages/Offer';
import Catalogue from './pages/Catalogue';
import Releases from './pages/Releases';
import Release from './pages/Release';
import ReleaseByPublisher from './pages/ReleaseByPublisher';
import Book from './pages/Book';
import Cart from './pages/Cart';
import BookInRelease from './pages/BookInRelease';
import News from './pages/News';
import Help from './pages/Help';
import Contact from './pages/Contact';
import ContentPage from './pages/ContentPage';
import Order from './pages/Order';
import SavedOrder from './pages/SavedOrder';
import Invoices from './pages/Invoices';
import ActAsUser from './pages/ActAsUser';
import Maintenance from './pages/Maintenance';

import {
    PATH_HOME,
    PATH_LOGIN,
    PATH_FORGOT_PASS,
    PATH_RESET_PASS,
    PATH_REQUEST_CONFIRMAION,
    PATH_CONFIRM_EMAIL,
    PATH_RELEASES,
    PATH_CATALOGUE,
    PATH_OFFERS,
    PATH_NEWS,
    PATH_ACCOUNT,
    PATH_CART,
    PATH_SAVED_ORDERS,
    PATH_SAVED_ORDER,
    PATH_CHECKOUT,
    PATH_ORDER,
    PATH_HELP,
    PATH_CONTACT,
    PATH_ORDER_HISTORY,
    PATH_ACT_AS_USER,
    PATH_INVOICES,
    FEATURE_INVOICES
} from './constants';

import { connect } from 'react-redux';
import { fetchProfile, logout } from './redux/User';
import { checkSiteStatus } from './redux/Ui';
const mapStateToProps = state => {
  return {
    maintenance: state.ui.maintenance,
    isAuthed: state.user.isAuthed,
    isLoading: state.user.isLoading,
    error: state.user.error
  }
}
const mapDispatchToProps = dispatch => {
    return {
        checkSiteStatus: () => { dispatch(checkSiteStatus()); },
        logout: () => { dispatch(logout()); },
        fetchProfile: (cb, errorCb, auth) => { dispatch(fetchProfile(cb, errorCb, auth)); }
    }
}

const PrivateRoute = ({ children, isAuthed, error, ...rest }) => {
    let hasCookie = true;
    if (typeof window !== 'undefined') {
        let a = JSON.parse(cookie.getItem('auth'));
        if (a && a.accessToken) {
            hasCookie = true;
        } else {
            hasCookie = false;
        }
        // console.log("PrivateRoute", hasCookie, a, error);
    }
    if (!hasCookie || (error && error !== null)) {
        return (<Route {...rest} render={({ location }) => (<Redirect to={{ pathname: PATH_LOGIN, state: { from: location } }} />)} />);
    }
    return (
        <Route {...rest}>{isAuthed ? children : <Loader type="placeholder" />}</Route>
    );
}

class App extends React.Component {

    componentDidMount() {
        this.props.checkSiteStatus();
        var path = this.props.location.pathname;
        var params = queryString.parse(this.props.location.search);
        //if the page is password reset and we have appropriate parameters for a password reset, then log the user out first.
        if (path === PATH_RESET_PASS && params.id && params.token) {
            this.props.logout();
        } else {
            this.props.fetchProfile();
        }
    }

    render() {
        if (this.props.maintenance) {
          return (
            <div id="app">
              <Maintenance />
            </div>
          );
        }
        return (
            <div id="app" className="">
                {this.props.isAuthed && <Header />}
                <Switch>
                    <PrivateRoute exact path={PATH_HOME} isAuthed={this.props.isAuthed} error={this.props.error}><Home /></PrivateRoute>
                    <Route path={[PATH_LOGIN, PATH_FORGOT_PASS, PATH_RESET_PASS, PATH_REQUEST_CONFIRMAION, PATH_CONFIRM_EMAIL]}>
                        <Login />
                    </Route>

                    <PrivateRoute path={`${PATH_RELEASES}/:monthYear/publisher/:publisher/:orderFormId/:isbn`} isAuthed={this.props.isAuthed} error={this.props.error}><BookInRelease /></PrivateRoute>
                    <PrivateRoute path={`${PATH_RELEASES}/:monthYear/publisher/:publisher/:orderFormId?`} isAuthed={this.props.isAuthed} error={this.props.error}><ReleaseByPublisher /></PrivateRoute>
                    <PrivateRoute path={`${PATH_RELEASES}/:monthYear/:orderFormId/:isbn`} isAuthed={this.props.isAuthed} error={this.props.error}><BookInRelease /></PrivateRoute>
                    <PrivateRoute path={`${PATH_RELEASES}/:monthYear/:orderFormId?`} isAuthed={this.props.isAuthed} error={this.props.error}><Release /></PrivateRoute>
                    <PrivateRoute path={PATH_RELEASES} isAuthed={this.props.isAuthed} error={this.props.error}><Releases /></PrivateRoute>

                    <PrivateRoute path={`${PATH_CATALOGUE}/:isbn`} isAuthed={this.props.isAuthed} error={this.props.error}><Book /></PrivateRoute>
                    <PrivateRoute path={PATH_CATALOGUE} isAuthed={this.props.isAuthed} error={this.props.error}><Catalogue /></PrivateRoute>

                    <PrivateRoute path={`${PATH_OFFERS}/:slug`} isAuthed={this.props.isAuthed} error={this.props.error}><Offer /></PrivateRoute>
                    <PrivateRoute path={PATH_OFFERS} isAuthed={this.props.isAuthed} error={this.props.error}><Offers /></PrivateRoute>

                    <PrivateRoute path={PATH_NEWS} isAuthed={this.props.isAuthed} error={this.props.error}><News /></PrivateRoute>

                    <PrivateRoute path={`${PATH_ORDER_HISTORY}/:orderId`} isAuthed={this.props.isAuthed} error={this.props.error}><Order /></PrivateRoute>
                    <PrivateRoute path={PATH_ACCOUNT} isAuthed={this.props.isAuthed} error={this.props.error}><Account /></PrivateRoute>
                    <PrivateRoute path={[`${PATH_SAVED_ORDERS}/:orderId`, `${PATH_ORDER}/:orderId`]} isAuthed={this.props.isAuthed} error={this.props.error}><Order /></PrivateRoute>
                    <PrivateRoute path={[PATH_CART, PATH_SAVED_ORDERS, PATH_CHECKOUT]} isAuthed={this.props.isAuthed} error={this.props.error}><Cart /></PrivateRoute>
                    <PrivateRoute path={[`${PATH_SAVED_ORDER}/:orderId`]} isAuthed={this.props.isAuthed} error={this.props.error}><SavedOrder /></PrivateRoute>
                    {FEATURE_INVOICES && <PrivateRoute path={PATH_INVOICES} isAuthed={this.props.isAuthed} error={this.props.error}><Invoices /></PrivateRoute>}

                    <PrivateRoute path={`${PATH_HELP}/:category?/:question?`} isAuthed={this.props.isAuthed} error={this.props.error}><Help /></PrivateRoute>
                    <PrivateRoute path={PATH_CONTACT} isAuthed={this.props.isAuthed} error={this.props.error}><Contact /></PrivateRoute>

                    <PrivateRoute path={PATH_ACT_AS_USER} isAuthed={this.props.isAuthed} error={this.props.error}><ActAsUser /></PrivateRoute>

                    <Route path="/:slug"><ContentPage /></Route>
                </Switch>
                {this.props.isAuthed && <Footer />}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
