import React from 'react';
import { withRouter } from 'react-router';
import { request } from '../functions/apiRequestWrapper';
import BookListItem from '../components/BookListItem';
import SectionListItem from '../components/SectionListItem';
import SectionListPublisherItem from '../components/SectionListPublisherItem';
import Loader from '../components/Loader';
import Breadcrumb from '../components/Breadcrumb';
import DownloadS3Button from '../components/DownloadS3Button';
import Button from 'react-bootstrap/Button';
import { Download } from 'react-bootstrap-icons';
import { PATH_RELEASES } from '../constants';

import { db } from '../db';

import { connect } from 'react-redux';
import { logout } from '../redux/User';
const mapStateToProps = state => {
    return {
        country: state.user.data && state.user.data.country ? state.user.data.country : "AU"
    }
}
const mapDispatchToProps = dispatch => {
    return {
        logout: () => { dispatch(logout()); }
    }
}

class Release extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            releaseData: null,
            sectionInfo: {},
            isLoadingRelease: false,
            isLoadingSection: false
        }
    }

    componentDidMount() {
        setTimeout(() => {window.scrollTo(0, 0);}, 100);
        const { match } = this.props;
        this.getRelease();
        if (match.params.orderFormId) {
            this.getReleaseSection();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.orderFormId && prevProps.match.params.orderFormId !== this.props.match.params.orderFormId) {
            this.getReleaseSection(this.props.match.params.monthYear, this.props.match.params.orderFormId);
            if (!prevProps.match.params.orderFormId) {
                window.scrollTo(0, 0);
            } else {
                document.getElementById("sectionstop").scrollIntoView();
            }
        }
        if (!this.props.match.params.orderFormId && prevProps.match.params.orderFormId) {
            this.setState({sectionInfo: {}});
        }
    }

    getRelease = (_monthYear) => {
        let monthYear = _monthYear || this.props.match.params.monthYear;
        this.setState({ isLoadingRelease: true });
        return request(
            `${process.env.REACT_APP_API}/1/releases/${monthYear}`
        ).then((data) => {
            // console.log(data);
            this.setState({ releaseData: data, isLoadingRelease: false });
        }).catch((error) => {
            if (error.status === 401) { this.props.logout(); }
            db.releases.get(monthYear).then((offlineRelease) => {
                // console.log("getRelease offline", offlineRelease);
                db.sections.orderBy("idx").filter(o => o.parentSlug === monthYear).toArray().then((offlineSections) => {
                    // console.log("getRelease offline section", offlineSections);
                    offlineRelease.sections = offlineSections;
                    this.setState({ releaseData: offlineRelease, isLoadingRelease: false });
                });
            }).catch(() => {
                this.setState({ isLoadingRelease: false });
            });
        });
    }

    getReleaseSection = (_monthYear, _orderFormId) => {
        const { match } = this.props;
        let monthYear = _monthYear || match.params.monthYear;
        let orderFormId = _orderFormId || match.params.orderFormId;
        if (!orderFormId || typeof orderFormId === "undefined") {
            return;
        }
        this.setState({ isLoadingSection: true });

        request(
            `${process.env.REACT_APP_API}/1/releases/${monthYear}/${orderFormId}/products`
        ).then((data) => {
            this.setState({
                sectionInfo: data.links,
                sectionProducts: data.products,
                isLoadingSection: false
            }, this.scrollToBook);
        }).catch((error) => {
            db.sections.get(parseInt(orderFormId)).then((offlineSection) => {
                // console.log("db sections get", orderFormId, offlineSection);
                if (offlineSection.products) {
                    db.products.where("isbn").anyOf(offlineSection.products).toArray().then((offlineProducts) => {
                        // console.log("db products where isbn", offlineProducts);
                        this.setState({
                            sectionInfo: offlineSection,
                            sectionProducts: offlineProducts,
                            isLoadingSection: false
                        });
                    });
                } else {
                    this.setState({
                        sectionInfo: offlineSection,
                        sectionProducts: [],
                        isLoadingSection: false
                    }, this.scrollToBook);
                }
            }).catch(() => {
                this.setState({ isLoadingSection: false });
            });
        });
    }
    
    scrollToBook = () => {
        const { location } = this.props;
        if (location.state && location.state.isbn) {
            // console.log("scrollToBook", location.state.isbn);
            let el = document.getElementById(`book${location.state.isbn}`);
            if (el) {
                el.scrollIntoView();
            }
        }
    }

    render() {
        const { match } = this.props;
        const { releaseData, sectionInfo, sectionProducts, isLoadingRelease, isLoadingSection } = this.state;
        const { monthYear, orderFormId } = match.params;
        const title = releaseData && releaseData.catalogue && releaseData.catalogue.toLowerCase() + " releases";
        let crumbs = [{ link: PATH_RELEASES, label: "Releases" }];
        let currentCrumb = title;
        if (orderFormId && sectionInfo.orderFormTitle) {
          crumbs.push({ link: `${PATH_RELEASES}/${monthYear}`, label: title });
          currentCrumb = sectionInfo.orderFormTitle;
        }
        return (
            <div className="page releases container px-3 px-md-5">
                <Breadcrumb crumbs={crumbs}>{currentCrumb}</Breadcrumb>
                <div className="row mb-4">
                    <div className="col-12 col-md-auto">
                        <h1 className="text-capitalize mb-5">{title}</h1>
                    </div>
                    {releaseData && releaseData.links && <div className="col-12 col-md text-md-right pt-2">
                        {releaseData.links.catalogueOnixBasic &&
                            <DownloadS3Button bucket={'catalogue/onix/' + this.props.country} file={releaseData.links.catalogueOnixBasic} className="mr-2 mb-2" label="Short ONIX" />
                        }
                        {releaseData.links.catalogueOnixFull &&
                            <DownloadS3Button bucket={'catalogue/onix/' + this.props.country} file={releaseData.links.catalogueOnixFull} className="mr-2 mb-2" label="Full ONIX" />
                        }
                        {releaseData.links.orderForm &&
                            <Button href={`/order_zips/${this.props.country}/${releaseData.links.orderForm}`} download target="_blank" variant="outline-primary" className="mr-2 mb-2"><Download size={16} className="mr-1" /> All order forms</Button>
                        }
                        {releaseData.links.allCovers &&
                            <Button href={`/covers/${releaseData.links.allCovers}`} download target="_blank" variant="outline-primary" className="mr-2 mb-2"><Download size={16} className="mr-1" /> Covers</Button>
                        }
                    </div>}
                </div>

                {releaseData && releaseData.sections && !orderFormId && <div className="list releases">
                    <div className="row product-row">
                        {releaseData.sections.map(section => {
                            return (
                                <div key={section.orderFormId} className="col-6 col-sm-4 col-md-3">
                                    <SectionListItem monthYear={monthYear} className="mb-5" {...section} />
                                </div>
                            );
                        })}
                    </div>
                    {this.props.country === 'NZ' && releaseData && releaseData.publishers &&
                        <div>
                            <div className="row">
                                <h2 className="my-5">By Publisher</h2>
                            </div>
                            <div className="row">
                                {releaseData.publishers.map(section => {
                                    return (
                                        <div key={section.slug} className="col-6 col-sm-4 col-md-3">
                                           <SectionListPublisherItem monthYear={monthYear} publisher={section.slug} className="mb-5" {...section} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    }
                </div>}

                <br />

                <div id="sectionstop" />

                {orderFormId && sectionInfo.orderFormId && !isLoadingSection && <>
                    <div className="row mb-4">
                        <div className="col-12 col-md-auto">
                            <h3 className="mb-2">{sectionInfo.orderFormTitle}</h3>
                        </div>
                        <div className="col-12 col-md text-md-right">
                            {sectionInfo.catalogueOnixBasic &&
                                <DownloadS3Button bucket={'catalogue/onix/' + this.props.country} file={sectionInfo.catalogueOnixBasic} className="mr-2 mb-2" label="Short ONIX" />
                            }
                            {sectionInfo.catalogueOnixFull &&
                                <DownloadS3Button bucket={'catalogue/onix/' + this.props.country} file={sectionInfo.catalogueOnixFull} className="mr-2 mb-2" label="Full ONIX" />
                            }
                            {sectionInfo.orderForm &&
                                <Button href={`/orders/${this.props.country}/${sectionInfo.orderForm}`} download target="_blank" variant="outline-primary" className="mr-2 mb-2"><Download size={16} className="mr-1" /> Order Form</Button>
                            }
                        </div>
                    </div>
                    <div className="row product-row">
                        {sectionProducts && sectionProducts.map((product, i) => {
                            return (
                                <div key={product.isbn} className="col-6 col-sm-4 col-md-3" id={`book${product.isbn}`}>
                                    <BookListItem monthYear={monthYear} orderFormId={sectionInfo.orderFormId} className="mb-5" {...product} />
                                </div>
                            );
                        })}
                    </div>
                </>}
                
                {(isLoadingRelease || isLoadingSection) && <Loader type="placeholder" />}
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Release));
