const logo = () => {
  return (
    <svg className="hqlogo" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 366.98 156.09" width="366.98" height="156.09">
      <g>
        <polygon style={{fill:"#7ECFF4"}} points="0,19.12 0,0 16.56,9.56 33.12,19.12 16.56,28.68 0,38.24"/>
        <polygon style={{fill:"#ED1E24"}} points="0,98.73 0,79.6 16.56,89.17 33.12,98.73 16.56,108.29 0,117.85"/>
        <polygon style={{fill:"#A4282B"}} points="0,136.97 0,117.85 16.56,127.4 33.12,136.97 16.56,146.53 0,156.09"/>
        <polygon style={{fill:"#184999"}} points="33.12,38.24 33.12,19.12 49.68,28.68 66.24,38.24 49.68,47.8 33.12,57.36"/>
        <polygon style={{fill:"#ED1E24"}} points="33.12,79.61 33.12,60.48 49.68,70.04 66.24,79.61 49.68,89.17 33.12,98.73"/>
        <polygon style={{fill:"#ED1E24"}} points="33.12,117.85 33.12,98.73 49.68,108.29 66.24,117.85 49.68,127.41 33.12,136.97"/>
        <polygon style={{fill:"#A50F12"}} points="66.24,98.72 66.24,117.85 49.68,108.28 33.12,98.72 49.68,89.16 66.24,79.6"/>
        <polygon style={{fill:"#ED1E24"}} points="66.24,60.48 66.24,79.6 49.68,70.04 33.12,60.48 49.68,50.92 66.24,41.36"/>
        <polygon style={{fill:"#ED1E24"}} points="33.12,117.85 33.12,136.97 16.56,127.41 0,117.85 16.56,108.29 33.12,98.73"/>
        <polygon style={{fill:"#EF4130"}} points="33.12,79.6 33.12,98.72 16.56,89.17 0,79.6 16.56,70.04 33.12,60.48"/>
        <polygon style={{fill:"#1496CF"}} points="33.12,38.24 33.12,57.36 16.56,47.8 0,38.24 16.56,28.68 33.12,19.12"/>
      </g>
      <g>
        <path style={{fill:"currentColor"}} d="M99.16,65.11c0-4.5-0.91-5.93-3.98-6.06c-1.37-0.07-1.76-0.33-1.76-0.98c0-0.72,0.65-1.17,1.63-1.17 c0.46,0,1.17,0.07,1.83,0.13c3,0.26,5.02,0.46,5.74,0.46c0.59,0,2.61-0.2,5.61-0.46c0.65-0.07,1.37-0.13,1.83-0.13 c0.98,0,1.63,0.46,1.63,1.17c0,0.65-0.33,0.85-1.56,0.98c-1.3,0.07-1.96,0.59-2.35,1.69c-0.26,0.85-0.52,3.13-0.52,4.37v9.71 c0,0.98,0.39,1.3,1.5,1.3h15.78c1.04,0,1.37-0.33,1.37-1.5v-9.52c0-4.3-0.91-5.93-3.52-6.06c-1.3-0.07-1.69-0.33-1.69-0.91 c0-0.78,0.65-1.24,1.63-1.24c0.46,0,1.17,0.07,1.83,0.13c2.8,0.26,4.69,0.46,5.41,0.46s2.61-0.2,5.41-0.46 c0.65-0.07,1.37-0.13,1.83-0.13c0.98,0,1.63,0.46,1.63,1.17c0,0.65-0.33,0.85-1.56,0.98c-1.3,0.07-1.96,0.59-2.35,1.69 c-0.26,0.91-0.52,3-0.52,4.37v27.38c0,4.37,0.98,5.93,3.85,6.06c1.37,0.06,1.76,0.26,1.76,0.91c0,0.72-0.65,1.17-1.63,1.17 c-0.39,0-1.17-0.06-1.83-0.13c-2.61-0.26-4.56-0.39-5.54-0.39c-1.04,0-3,0.13-5.67,0.39c-0.65,0.07-1.43,0.13-1.83,0.13 c-0.98,0-1.63-0.46-1.63-1.17c0-0.65,0.33-0.85,1.56-0.91c1.3-0.13,1.96-0.65,2.35-1.76c0.26-0.85,0.52-3.06,0.52-4.3v-11.6 c0-1.17-0.33-1.56-1.37-1.56h-15.71c-0.98,0-1.56,0.59-1.56,1.56v11.6c0,4.3,1.24,5.93,4.5,6.06c1.43,0,1.89,0.26,1.89,0.91 c0,0.72-0.65,1.17-1.63,1.17c-0.39,0-1.17-0.06-1.83-0.13c-2.8-0.26-4.89-0.39-6.06-0.39c-1.11,0-3.19,0.13-6,0.39 c-0.65,0.07-1.43,0.13-1.83,0.13c-0.98,0-1.63-0.46-1.63-1.17c0-0.65,0.33-0.85,1.56-0.91c1.3-0.13,2.02-0.65,2.35-1.76 c0.33-0.85,0.59-3.06,0.59-4.3V65.11z"/>
        <path style={{fill:"currentColor"}} d="M162.14,93.99c0,2.35,0.72,3.39,2.35,3.39c0.39,0,0.72-0.07,1.17-0.26c0.33-0.13,0.52-0.2,0.59-0.2 c0.33,0,0.59,0.26,0.59,0.65c0,1.56-3,3.19-6,3.19c-2.22,0-4.17-0.85-5.02-2.22c-0.33-0.46-0.33-0.52-0.39-0.52h-0.07 c-0.07,0-0.2,0.13-0.33,0.33c-0.13,0.2-0.13,0.2-1.76,1.43c-1.24,0.85-3.72,1.5-5.93,1.5c-4.37,0-6.84-2.41-6.84-6.58 c0-4.89,2.8-6.91,13.17-9.32c1.04-0.26,1.24-0.46,1.24-1.24v-2.93c0-3.06-1.43-4.82-3.85-4.82c-1.83,0-3.13,1.17-3.13,2.74 c0,0.46,0.13,1.3,0.33,2.09c0.07,0.39,0.13,0.65,0.13,0.78c0,0.46-0.33,0.78-0.98,0.98c-0.85,0.2-2.93,0.46-3.98,0.46 c-1.43,0-1.96-0.46-1.96-1.76c0-4.69,4.76-7.89,11.73-7.89c3.32,0,5.8,0.78,7.37,2.28c1.11,1.17,1.56,2.48,1.56,4.82V93.99z M154.9,87.6c0-0.26,0-0.33-0.07-0.33c-0.07,0-0.13,0-0.26,0.07c-0.07,0.06-0.33,0.2-0.59,0.26c-4.95,1.63-6.45,3.13-6.45,6.45 c0,2.22,1.24,3.58,3.19,3.58c2.41,0,4.17-1.5,4.17-3.46V87.6z"/>
        <path style={{fill:"currentColor"}} d="M177.84,78.93c0.26-0.33,0.26-0.39,0.59-0.85c2.02-3,3.85-4.5,5.41-4.5c1.7,0,4.89,2.61,4.89,3.98 c0,0.65-0.33,1.5-1.04,2.54c-0.78,1.17-1.5,1.7-2.28,1.7c-0.72,0-1.43-0.39-2.48-1.24c-1.04-0.91-1.63-1.24-2.48-1.24 c-1.17,0-2.61,1.96-2.61,3.65v11.21c0,3.39,0.72,4.43,3.13,4.63c0.98,0.07,1.24,0.26,1.24,0.85c0,0.59-0.52,0.98-1.17,0.98 c-0.26,0-0.72-0.06-1.3-0.13c-1.69-0.2-3.26-0.33-4.63-0.33c-1.37,0-2.93,0.13-4.76,0.33c-0.59,0.07-1.11,0.13-1.3,0.13 c-0.72,0-1.17-0.39-1.17-0.98c0-0.52,0.26-0.72,0.98-0.85c1.43-0.26,1.76-1.17,1.76-4.63V82.91c0-3.45-0.59-4.24-3.46-4.56 c-0.46-0.07-0.85-0.39-0.85-0.78c0-0.72,0.59-1.04,2.22-1.3c2.41-0.52,6.13-1.83,7.95-2.8c0.59-0.26,0.85-0.39,1.11-0.39 c0.2,0,0.33,0.2,0.33,0.59l-0.07,3.85V78.93z"/>
        <path style={{fill:"currentColor"}} d="M193.74,82.91c0-3.52-0.46-4.11-3.46-4.56c-0.46-0.07-0.85-0.46-0.85-0.78c0-0.72,0.52-1.04,2.22-1.3 c0.98,0,5.8-1.76,7.89-2.8c0.59-0.26,0.91-0.39,1.11-0.39c0.33,0,0.39,0.2,0.39,0.91c0,0.59,0,2.61-0.06,3.78 c0.33-0.2,0.33-0.2,0.72-0.59c2.02-2.28,4.63-3.39,7.56-3.39c6.39,0,11.02,5.08,11.02,12.19c0,8.8-5.87,15.32-13.75,15.32 c-1.83,0-4.43-0.78-5.21-1.5l-0.2-0.2c-0.07,0-0.13,0.26-0.13,0.52v6.13c0,3.65,0.39,4.24,3.13,4.63c0.98,0.07,1.3,0.26,1.3,0.78 c0,0.59-0.46,0.98-1.17,0.98c-0.2,0-0.78-0.07-1.3-0.13c-2.09-0.2-3.65-0.33-4.76-0.33c-1.04,0-2.67,0.13-4.76,0.33 c-0.52,0.07-1.11,0.13-1.3,0.13c-0.65,0-1.17-0.39-1.17-0.98c0-0.46,0.2-0.65,0.98-0.78c1.43-0.33,1.83-1.3,1.83-4.63V82.91z M200.98,91.97c0,4.43,2.15,7.1,5.74,7.1c3.98,0,6.52-3.65,6.52-9.45c0-7.17-2.87-12.06-7.11-12.06c-3.06,0-5.15,2.35-5.15,5.8 V91.97z"/>
        <path style={{fill:"currentColor"}} d="M229.98,85.38c-0.65,0-0.78,0.13-0.78,1.04c0,2.15,0.85,5.15,1.96,7.04c1.56,2.67,4.17,4.17,6.91,4.17 c2.22,0,4.63-0.91,6.39-2.35c0.85-0.78,1.17-0.91,1.56-0.91s0.65,0.26,0.65,0.59c0,0.46-0.39,1.3-0.91,1.89 c-2.35,2.74-6.52,4.43-10.82,4.43c-7.69,0-12.97-5.48-12.97-13.49c0-8.21,5.34-14.02,12.84-14.02c6.39,0,11.73,4.76,11.73,10.43 c0,0.98-0.33,1.17-1.76,1.17H229.98z M238.25,83.23c0.78,0,1.04-0.33,1.04-1.17c0-3.52-2.09-6.32-4.69-6.32 c-2.87,0-5.28,3.13-5.28,6.84c0,0.52,0.13,0.65,0.72,0.65H238.25z"/>
        <path style={{fill:"currentColor"}} d="M258.2,78.93c0.26-0.33,0.26-0.39,0.59-0.85c2.02-3,3.85-4.5,5.41-4.5c1.69,0,4.89,2.61,4.89,3.98 c0,0.65-0.33,1.5-1.04,2.54c-0.78,1.17-1.5,1.7-2.28,1.7c-0.72,0-1.44-0.39-2.48-1.24c-1.04-0.91-1.63-1.24-2.48-1.24 c-1.17,0-2.61,1.96-2.61,3.65v11.21c0,3.39,0.72,4.43,3.13,4.63c0.98,0.07,1.24,0.26,1.24,0.85c0,0.59-0.52,0.98-1.17,0.98 c-0.26,0-0.72-0.06-1.3-0.13c-1.7-0.2-3.26-0.33-4.63-0.33c-1.37,0-2.93,0.13-4.76,0.33c-0.59,0.07-1.11,0.13-1.3,0.13 c-0.72,0-1.17-0.39-1.17-0.98c0-0.52,0.26-0.72,0.98-0.85c1.44-0.26,1.76-1.17,1.76-4.63V82.91c0-3.45-0.59-4.24-3.45-4.56 c-0.46-0.07-0.85-0.39-0.85-0.78c0-0.72,0.59-1.04,2.21-1.3c2.41-0.52,6.13-1.83,7.95-2.8c0.59-0.26,0.85-0.39,1.11-0.39 c0.2,0,0.32,0.2,0.32,0.59l-0.06,3.85V78.93z"/>
        <path style={{fill:"currentColor"}} d="M279.68,65.11c0-4.5-0.91-5.93-3.97-6.06c-1.37-0.07-1.76-0.33-1.76-0.98c0-0.72,0.65-1.17,1.63-1.17 c0.46,0,1.17,0.07,1.82,0.13c3,0.26,5.02,0.46,5.74,0.46c0.59,0,2.61-0.2,5.61-0.46c0.65-0.07,1.37-0.13,1.82-0.13 c0.98,0,1.63,0.46,1.63,1.17c0,0.65-0.32,0.85-1.56,0.98c-1.3,0.07-1.96,0.59-2.35,1.69c-0.26,0.85-0.52,3.13-0.52,4.37v9.71 c0,0.98,0.39,1.3,1.5,1.3h15.77c1.04,0,1.37-0.33,1.37-1.5v-9.52c0-4.3-0.91-5.93-3.52-6.06c-1.3-0.07-1.7-0.33-1.7-0.91 c0-0.78,0.65-1.24,1.63-1.24c0.46,0,1.17,0.07,1.82,0.13c2.8,0.26,4.69,0.46,5.41,0.46s2.61-0.2,5.41-0.46 c0.65-0.07,1.37-0.13,1.83-0.13c0.98,0,1.63,0.46,1.63,1.17c0,0.65-0.33,0.85-1.56,0.98c-1.3,0.07-1.96,0.59-2.35,1.69 c-0.26,0.91-0.52,3-0.52,4.37v27.38c0,4.37,0.98,5.93,3.85,6.06c1.37,0.06,1.76,0.26,1.76,0.91c0,0.72-0.65,1.17-1.63,1.17 c-0.39,0-1.17-0.06-1.83-0.13c-2.61-0.26-4.56-0.39-5.54-0.39c-1.04,0-3,0.13-5.67,0.39c-0.65,0.07-1.44,0.13-1.83,0.13 c-0.98,0-1.63-0.46-1.63-1.17c0-0.65,0.33-0.85,1.56-0.91c1.3-0.13,1.96-0.65,2.35-1.76c0.26-0.85,0.52-3.06,0.52-4.3v-11.6 c0-1.17-0.33-1.56-1.37-1.56h-15.71c-0.98,0-1.56,0.59-1.56,1.56v11.6c0,4.3,1.24,5.93,4.5,6.06c1.43,0,1.89,0.26,1.89,0.91 c0,0.72-0.65,1.17-1.63,1.17c-0.39,0-1.17-0.06-1.82-0.13c-2.8-0.26-4.89-0.39-6.06-0.39c-1.11,0-3.19,0.13-6,0.39 c-0.65,0.07-1.43,0.13-1.82,0.13c-0.98,0-1.63-0.46-1.63-1.17c0-0.65,0.32-0.85,1.56-0.91c1.3-0.13,2.02-0.65,2.35-1.76 c0.33-0.85,0.59-3.06,0.59-4.3V65.11z"/>
        <path style={{fill:"currentColor"}} d="M337.78,104.29c-3.52-2.02-4.69-2.48-6.65-2.48c-2.8,0-4.37,1.69-4.89,5.15c-0.06,0.85-0.39,1.17-0.98,1.17 c-0.72,0-1.17-0.65-1.17-1.83c0-3.98,2.74-7.76,5.87-8.08c0.13-0.07,0.2-0.07,0.26-0.13c-1.7-0.78-2.54-1.24-2.54-1.24 c-1.96-1.04-4.43-4.24-6.13-7.76c-1.5-3.26-2.28-6.65-2.28-10.3c0-12.91,9.78-22.55,22.81-22.55c13.43,0,22.75,8.93,22.75,21.9 c0,9.65-5.54,18.25-14.02,21.58c-1.11,0.46-3.65,0.98-4.63,0.98c-0.52,0-0.52,0-0.59,0.07c0.2,0.07,0.39,0.13,0.52,0.2 c1.11,0.26,2.09,0.65,3.78,1.56l5.41,2.87c3.91,2.09,4.04,2.15,5.48,2.15c1.76,0,2.93-0.39,4.56-1.5c0.2-0.13,0.52-0.26,0.78-0.26 c0.52,0,0.85,0.33,0.85,0.78c0,0.65-0.85,1.83-2.28,3.06c-2.35,2.02-5.47,3.19-8.41,3.19c-2.8,0-5.61-0.91-9.26-3.13L337.78,104.29 z M327.74,75.22c0,7.11,1.43,12.71,4.43,17.08c3,4.3,6.78,6.45,11.28,6.45c7.95,0,12.91-6.65,12.91-17.4 c0-12.78-6.84-22.75-15.51-22.75C332.82,58.59,327.74,65.05,327.74,75.22z"/>
      </g>
    </svg>
  );
}
export default logo;
