import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import BookStack from './BookStack';
import { PATH_RELEASES } from '../constants';

const Section = ({monthYear, publisher, orderFormTitle, imageUrls, className, orderFormId}) => {

    var link = `${PATH_RELEASES}/${monthYear}/publisher/${publisher}`;
    if (orderFormId && orderFormId > 0) {
      link = `${PATH_RELEASES}/${monthYear}/publisher/${publisher}/${orderFormId}`;
    }

    return (
        <NavLink
            to={link}
            className={classnames("section-list-item", className)}
        >
            <BookStack images={imageUrls} />
            <span className="section-label text-capitalize d-block">
                {orderFormTitle && orderFormTitle.toLowerCase()}
            </span>
        </NavLink>
    );
}

export default Section;
