import { Link } from 'react-router-dom';
import BookStack from './BookStack';
import { PATH_RELEASES } from '../constants';

const Release = ({release}) => {

    return (
        <Link to={`${PATH_RELEASES}/${release.slug}`} className="release-list-item mb-5">
          <BookStack images={release.imageUrls} />
          <div className="release-label text-capitalize">
            {release.catalogue && release.catalogue.toLowerCase()}
          </div>
        </Link>
    );
}
export default Release;
