import { request } from '../functions/apiRequestWrapper';
import { logout } from './User';

const SET_LIST = "HISTORY/SET_LIST";
const CLEAR_LIST = "HISTORY/CLEAR_LIST";
const SET_PAGE = "HISTORY/SET_PAGE";
const SET_SORT = "HISTORY/SET_SORT";
const SET_LOADING = "HISTORY/SET_LOADING";
const SET_ERROR = "HISTORY/SET_ERROR";
const SET_SEARCH = "HISTORY/SET_SEARCH";
const LOGOUT = "HISTORY/LOGOUT";

export function getOrderHistory(_page, _orderBy) {
    return (dispatch, getState) => {
        dispatch({ type: SET_LOADING, payload: true });
        const orderHistory = getState().orderHistory;
        let sortField = orderHistory.sortField;
        let currentPage = orderHistory.page;
        if (_page && typeof _page !== "undefined") {
            currentPage = _page;
            dispatch({type: SET_PAGE, payload: _page});
        }
        let skip = currentPage > 1 ? orderHistory.pageSize * (currentPage - 1) : 0;
        let orderBy = _orderBy || `${sortField} ${orderHistory.sortDir}`;
        let q = `?$top=${orderHistory.pageSize}&$skip=${skip}&$count=true&$orderby=${orderBy}`;
        if (orderHistory.search && orderHistory.search !== "") {
            let s = orderHistory.search.replace("'", "''");
            s = encodeURIComponent(s);
            let f = `&$filter=contains(reference, '${s}')`;
            // console.log("filter: ", f);
            q = q.concat(f);
        }
        request(
            `${process.env.REACT_APP_API}/1/orders${q}`
        ).then((data) => {
            dispatch({ type: SET_LIST, payload: data.items, count: data.count });
        }).catch((error) => {
            console.error(error);
            if (error.status === 401) {
                return dispatch(logout());
            }
            let msg = "An error has occured";
            if (error.body && error.body.message) {
                msg = error.body.message;
            }
            dispatch({ type: SET_ERROR, payload: msg });
        });
    }
}

export function setSort(field, dir) {
    return (dispatch, getState) => {
        dispatch({type: SET_SORT, field, dir});
        dispatch(getOrderHistory(undefined, `${field} ${dir}`));
    }
}

export function setSearch(s) {
    return {type: SET_SEARCH, payload: s};
}

export function clearList() {
    return {type: CLEAR_LIST};
}

export function historyLogout() {
    return {type: LOGOUT};
}

const defaultState = {
    list: [],
    count: 0,
    page: 1,
    pageSize: 12,
    sortField: "createDate", // "orderNumber", "amount"
    sortDir: "desc", // "asc"
    isLoading: false,
    error: null,
    search: "", // ref search field value
};

export function orderhistoryReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_LIST:
        return Object.assign({}, state, {
            list: action.payload,
            count: action.count,
            isLoading: false,
            error: null
        });
        case CLEAR_LIST:
        return Object.assign({}, state, {
            list: [],
            count: 0,
            page: 1,
            error: null
        });
        case SET_PAGE:
        return Object.assign({}, state, {
            page: action.payload
        });
        case SET_SORT:
        return Object.assign({}, state, {
            sortField: action.field || state.sortField,
            sortDir: action.dir || state.sortDir
        });
        case SET_LOADING:
        return Object.assign({}, state, {
            isLoading: action.payload
        });
        case SET_ERROR:
        return Object.assign({}, state, {
            error: action.payload,
            list: [],
            isLoading: false
        });
        case SET_SEARCH:
        return Object.assign({}, state, {
            search: action.payload,
            page: 1
        });
        case LOGOUT:
        return defaultState;

        default:
        return state;
    }
}
