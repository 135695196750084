// Store
import { thunk } from './middleware';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';

// Reducers
import { userReducer } from './User';
import { releasesReducer } from './Releases';
import { catalogueReducer } from './Catalogue';
import { cartReducer } from './Cart';
import { orderhistoryReducer } from './OrderHistory';
import { savedOrdersReducer } from './SavedOrders';
import { uiReducer } from './Ui';

const combinedReducers = combineReducers({
  user: userReducer,
  releases: releasesReducer,
  catalogue: catalogueReducer,
  cart: cartReducer,
  orderHistory: orderhistoryReducer,
  savedOrders: savedOrdersReducer,
  ui: uiReducer
});
let devTools = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = devTools ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

export const createAppStore = (initialState) => createStore(combinedReducers, initialState, composeEnhancers(applyMiddleware(thunk)));

// https://github.com/zalmoxisus/redux-devtools-extension
