import { useState } from "react";
import { request } from '../functions/apiRequestWrapper';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { Download } from 'react-bootstrap-icons';

const DownloadS3Button = (props) => {
    const [fetching, setFetching] = useState(false);
    const [error, setError] = useState(false);
    const download = () => {
        setFetching(true);
        let uri = props.uri ? props.uri : `${process.env.REACT_APP_API}/1/service/file?bucketName=${props.bucket}&fileName=${props.file}`;
        request(
            uri,
            { method: props.method ? props.method : 'POST' }
        ).then(response => {
            return response.blob();
        }).then(blob => {
            setFetching(false);
            let blobURL = URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = blobURL;
            a.style = "display: none";
            a.download = props.file ? props.file : true;
            document.body.appendChild(a);
            a.click();
        }).catch(error => {
            console.error(error);
            let msg = error.status === 404 ? "The file could not be found" : "An error has occured";
            setError(msg);
            setTimeout(() => { setError(false); }, 3000);
            setFetching(false);
        });
    };

    return (
        <>
        <Button
            onClick={(e) => { download(); e.preventDefault(); return false; }}
            variant={props.variant || "outline-primary"}
            className={props.className}>
            {fetching && <Spinner animation="border" size="sm" />}
            {!fetching && <Download size={16} className="mr-1" />}
            {` ` + props.label}
        </Button>
        <Alert variant="danger" className="fixedalert" dismissible onClose={() => { setError(false); }} show={Boolean(error)}><p className="my-2">{error}</p></Alert>
        </>
    );
}

export default DownloadS3Button;
