import React from 'react';
import Loader from '../../components/Loader';
import Pagination from '../../components/Pagination';
import OrderListItem from '../../components/OrderListItem';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { ChevronUp, ChevronDown, InfoCircleFill } from 'react-bootstrap-icons';

import { connect } from 'react-redux';
import { getOrderHistory, setSort, setSearch } from '../../redux/OrderHistory';
const mapStateToProps = state => {
    return {
        list: state.orderHistory.list,
        count: state.orderHistory.count,
        page: state.orderHistory.page,
        pageSize: state.orderHistory.pageSize,
        sortField: state.orderHistory.sortField,
        sortDir: state.orderHistory.sortDir,
        isLoading: state.orderHistory.isLoading,
        error: state.orderHistory.error,
        search: state.orderHistory.search
    };
}
const mapDispatchToProps = dispatch => {
    return {
        getOrderHistory: (p, o, s) => { dispatch(getOrderHistory(p, o, s)); },
        setSort: (f, d) => { dispatch(setSort(f, d)); },
        setSearch: (s) => { dispatch(setSearch(s)); }
    };
}

class OrderHistory extends React.Component {

    componentDidMount() {
        this.props.getOrderHistory();
    }

    handlePaginationChange = (idx) => {
        setTimeout(() => {window.scrollTo(0, 0);}, 800);
        this.props.getOrderHistory(idx + 1);
    }

    render() {
        const {list, isLoading, page, pageSize, sortField, sortDir, count, error, thisUser} = this.props;
        return (
            <div className="order-history mb-3 position-relative">

                <div className="row mb-5">
                    <div className="col-12 col-lg-auto"><h3 className="mb-3">Order History</h3></div>
                    <div className="col sorting">
                        <div className="row mx-0 justify-content-md-end">
                            <div className="col-12 col-md-auto px-0 mb-3 mb-md-0">
                                <span className="mx-1">Sort by</span>
                                <Button size="sm"
                                    variant={sortField === "createDate" ? "primary" : "secondary"}
                                    onClick={() => {this.props.setSort("createDate", sortDir)}}>
                                    Date
                                </Button>
                                <Button size="sm"
                                    variant={sortField === "orderNumber" ? "primary" : "secondary"}
                                    onClick={() => {this.props.setSort("orderNumber", sortDir)}}>
                                    Order ID
                                </Button>
                                <Button size="sm"
                                    variant={sortField === "amount" ? "primary" : "secondary"}
                                    onClick={() => {this.props.setSort("amount", sortDir)}}>
                                    Amount
                                </Button>
                                <div className="divider" />
                                {sortDir === "asc" && <Button size="sm"
                                    variant="secondary"
                                    onClick={() => {this.props.setSort(sortField, "desc")}}>
                                    Ascending <ChevronDown size={16} />
                                </Button>}
                                {sortDir === "desc" && <Button size="sm"
                                    variant="secondary"
                                    onClick={() => {this.props.setSort(sortField, "asc")}}>
                                    Descending <ChevronUp size={16} />
                                </Button>}
                                <div className="divider" />
                            </div>
                            <div className="col-12 col-md-auto px-0">
                                <span className="mx-1">
                                    Search
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="infotip">Searches the optional Reference field.</Tooltip>}
                                    >
                                        {({ref, ...triggerHandler}) => (
                                            <Button size="sm" variant="link" className="p-0" {...triggerHandler}>
                                                <InfoCircleFill size={16} ref={ref} />
                                            </Button>
                                        )}
                                    </OverlayTrigger>
                                </span>
                                <input placeholder="Reference"
                                    className="form-control form-control-sm"
                                    value={this.props.search}
                                    onChange={(e) => { this.props.setSearch(e.target.value); }}
                                    onKeyDown={(e) => { if (e.keyCode === 13) {this.props.getOrderHistory();} }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {isLoading && <Loader type={list && list.length > 0 ? "overlay" : "placeholder"} />}

                {(!isLoading && error) && <p className="alert alert-danger my-5">{error}</p>}

                {(!error && list && list.length > 0) && <div className="list order-history">
                    <div className="row product-row">
                        {list && list.map((order, i) => {
                            return (
                                <div key={order.orderNumber} className="col-6 col-sm-4 col-md-3">
                                    <OrderListItem order={order} thisUser={thisUser} />
                                </div>
                            );
                        })}
                    </div>
                </div>}

                {(count > pageSize) && <div className="mb-5">
                    <Pagination
                        totalSteps={Math.ceil(count / pageSize)}
                        activeIndex={page - 1}
                        onChange={this.handlePaginationChange}
                    />
                </div>}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);
