import React from 'react';
import classnames from 'classnames';
import { Search, ChevronRight } from 'react-bootstrap-icons';
import './search-field.scss';

import { connect } from 'react-redux';
import { setSearch, clearList } from '../../redux/Catalogue';
const mapStateToProps = state => {
    return {
        search: state.catalogue.search
    };
}
const mapDispatchToProps = dispatch => {
    return {
        setSearch: (s) => { dispatch(setSearch(s)); },
        clearList: () => { dispatch(clearList()); }
    };
}

class SearchField extends React.Component {

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
        this.state = {
            expanded: !props.expandable
        };
    }

    expand = () => {
        if (this.props.expandable) {
            let ex = !this.state.expanded;
            this.setState({expanded: ex});
            if (ex) {
                this.inputRef.current.focus();
            }
        }
        if (typeof this.props.onClick === "function") {
            this.props.onClick();
        }
    }

    handleChange = (e) => {
        this.props.setSearch(e.target.value);
    }

    handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            this.search();
        }
    }

    search = () => {
        this.props.clearList();
        if (this.props.collapseOnEnter) {
            this.setState({expanded: false});
        }
        if (typeof this.props.onEnter === "function") {
            this.props.onEnter();
        }
    }

    render() {
        return (
            <div className={classnames("search-field", this.props.className, {"expanded": this.state.expanded, "expandable": this.props.expandable})}>
                <button className="icon-wrapper" onClick={this.expand} aria-label="open search"><Search size={20} /></button>
                <input ref={this.inputRef} value={this.props.search} onChange={this.handleChange} onKeyDown={this.handleKeyDown} />
                {this.state.expanded && <button className="go" onClick={this.search}><ChevronRight size={28} /></button>}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchField);
