import React from 'react';
import { Formik, Form } from 'formik';
import FormField from '../../../components/FormField';
import Loader from '../../../components/Loader';
import Button from 'react-bootstrap/Button';
import { request } from '../../../functions/apiRequestWrapper';

import {
  PATH_LOGIN,
  PATH_FORGOT_PASS
} from '../../../constants';

class RequestConfirmationEmailForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      isSubmitting: false,
      submitStatus: null,
      serverError: false
    }
  }

  submitForm = (values, formikBag) => {
    this.setState({isSubmitting: true, submitStatus: null, serverError: false});
    request(
      `${process.env.REACT_APP_API}/1/account/email/request`,
      {
        method: 'POST',
        body: JSON.stringify(values)
      }
    ).then((response) => {
      this.setState({step: 2, submitStatus: 200, isSubmitting: false});
    }).catch((error) => {
      console.log(error);
      if (error.status === 403) {
        // email already confirmed
        this.setState({step: 2, submitStatus: error.status, isSubmitting: false});
      } else {
        // some other error
        let newState = {submitStatus: error.status, isSubmitting: false};
        if (error.body && error.body.modelState) {
          formikBag.setErrors(error.body.modelState);
        } else if (error.body && error.body.message) {
          newState.serverError = error.body.message;
        }
        this.setState(newState);
      }
    });
  }

  validateForm = (values) => {
    // TODO
    return {};
  }

  render() {
    const { step, submitStatus } = this.state;
    return (
      <Formik validate={this.validateForm} onSubmit={this.submitForm} initialValues={this.props.initialValues || {}}>
        {(formikBag) => (
          <Form className="p-5 position-relative">

            {this.state.isSubmitting && <Loader />}

            {step === 1 && <>
              <h3 className="mb-4">Request email confirmation</h3>
              <FormField id="email" label="Email" onChange={this.props.onChangeEmail} formikBag={formikBag} />
              <Button type="submit" variant="primary">Submit</Button>
              {this.state.serverError && <p className="">{this.state.serverError}</p>}
            </>}

            {step === 2 && submitStatus === 200 && <>
              <h3 className="mb-4">Your request has been sent</h3>
              <p>
                We've sent an email to {formikBag.values.email}.
                Please check your inbox and follow the instructions to complete your registration.
              </p>
            </>}

            {step === 2 && submitStatus === 403 && <>
              <h3 className="mb-4">Your email has already been confimed</h3>
              <p className="">You have previously confirmed your email address</p>
              <p className="">
                <Button variant="primary" onClick={() => { this.props.goTo(PATH_LOGIN, 1); }}>Login</Button>
              </p>
              <p className="">Have you forgotten your password?</p>
              <p>
                <Button variant="outline-primary" onClick={() => { this.props.goTo(PATH_FORGOT_PASS, 1); }}>Reset your password</Button>
              </p>
            </>}

          </Form>
        )}
      </Formik>
    );
  }
}

export default RequestConfirmationEmailForm;
