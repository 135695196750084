import Dexie from 'dexie';

export const db = new Dexie('hhqOfflineDb');
db.version(1).stores({
    products: '&isbn, authorName, releases',
    // isbn, title, format, formatSize, pages, category, publishDate, inStock, edition, price, imprint, bicCode, authorName, imageUrl, productSummary, productDetail, marketingPoints, aboutAuthor, releases

    sections: '&orderFormId, parentSlug, idx',
    // from api: orderFormId, orderFormTitle, imageUrls, coverImage, parentSlug, parentCatalogue,
    // not these: !orderForm, !catalogueOnixFull, !catalogueOnixBasic
    // added fe: products (list of ISBNs), idx (array index from the download, for sorting)

    releases: '&slug, yyyymm'
    // from api: slug, catalogue, imageUrls, coverImage,
    // not these: !links, !sections, !publishers
    // added fe: yyyymm (year and month numbers, converted from slug, for sorting)
});
