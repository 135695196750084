import React from 'react';
import { withRouter } from 'react-router-dom';
import Breadcrumb from '../components/Breadcrumb';
import { request } from '../functions/apiRequestWrapper';
import { connect } from 'react-redux';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

const mapStateToProps = state => {
    return {
        userData: state.user.data,
    }
}

// icon added in v1.8.0 of bootstrap-icons, not yet in react-bootstrap-icons
const Incognito = ({size, style}) => {
    return (<svg xmlns="http://www.w3.org/2000/svg" style={style} width={size} height={size} fill="currentColor" className="bi bi-incognito" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="m4.736 1.968-.892 3.269-.014.058C2.113 5.568 1 6.006 1 6.5 1 7.328 4.134 8 8 8s7-.672 7-1.5c0-.494-1.113-.932-2.83-1.205a1.032 1.032 0 0 0-.014-.058l-.892-3.27c-.146-.533-.698-.849-1.239-.734C9.411 1.363 8.62 1.5 8 1.5c-.62 0-1.411-.136-2.025-.267-.541-.115-1.093.2-1.239.735Zm.015 3.867a.25.25 0 0 1 .274-.224c.9.092 1.91.143 2.975.143a29.58 29.58 0 0 0 2.975-.143.25.25 0 0 1 .05.498c-.918.093-1.944.145-3.025.145s-2.107-.052-3.025-.145a.25.25 0 0 1-.224-.274ZM3.5 10h2a.5.5 0 0 1 .5.5v1a1.5 1.5 0 0 1-3 0v-1a.5.5 0 0 1 .5-.5Zm-1.5.5c0-.175.03-.344.085-.5H2a.5.5 0 0 1 0-1h3.5a1.5 1.5 0 0 1 1.488 1.312 3.5 3.5 0 0 1 2.024 0A1.5 1.5 0 0 1 10.5 9H14a.5.5 0 0 1 0 1h-.085c.055.156.085.325.085.5v1a2.5 2.5 0 0 1-5 0v-.14l-.21-.07a2.5 2.5 0 0 0-1.58 0l-.21.07v.14a2.5 2.5 0 0 1-5 0v-1Zm8.5-.5h2a.5.5 0 0 1 .5.5v1a1.5 1.5 0 0 1-3 0v-1a.5.5 0 0 1 .5-.5Z"/>
</svg>);
}

class ActAsUser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchAccountId: '',
            foundUser: null,
            isSearching: false,
        }
    }

    searchAccountIdChange = (e) => {
        this.setState({ searchAccountId: e.target.value });
    }

    searchUser = () => {
        this.setState({ isSearching: true });
        request(
            `${process.env.REACT_APP_API}/1/users/account/${this.state.searchAccountId.trim()}`
        ).then((data) => {
            console.log(this.props.userData.country);
            console.log(data.country);
            if (this.props.userData.country === data.country) {
                this.setState({ foundUser: data, isSearching: false });
            } else {
                this.setState({ foundUser: {}, isSearching: false });
            }
        }).catch((error) => {
            console.error(error);
            this.setState({ foundUser: {}, isSearching: false });
        });
    }

    actAsUser = () => {
        this.setState({ isSearching: true });
        request(
            `${process.env.REACT_APP_API}/1/users/actasuser/${this.state.searchAccountId.trim()}`,
            { method: 'POST' }
        ).then((data) => {
            localStorage.removeItem("currentCart");
            window.location.reload(false);
        }).catch((error) => {
            console.error(error);
            this.setState({ isSearching: false });
        });
    }

    actAsUserStop = () => {
        this.setState({ isSearching: true });
        request(
            `${process.env.REACT_APP_API}/1/users/actasuser`,
            { method: 'DELETE', }
        ).then((data) => {
            localStorage.removeItem("currentCart");
            window.location.reload(false);
        }).catch((error) => {
            console.error(error);
            this.setState({ isSearching: false });
        });
    }

    render() {
        const { userData } = this.props;
        return (
            <div className="page act-as-user container px-3 px-md-5">
                <Breadcrumb>Act as User</Breadcrumb>
                <h2 className="mb-3">Act As User</h2>

                {userData && userData.canActAsUser && userData.actingAsUser &&
                    <div className="mb-5">
                        <p className="mb-3">Acting as user <b>{userData.actingAsUser.firstName} {userData.actingAsUser.lastName} ({userData.actingAsUser.accountId})</b></p>
                        <Button onClick={this.actAsUserStop} variant="outline-primary">STOP ACTING AS THIS USER</Button>
                    </div>
                }

                {userData && userData.canActAsUser && !userData.actingAsUser && <>
                    <div className="mb-5">
                        <FormGroup>
                            <FormLabel>Enter Account ID</FormLabel>
                            <FormControl type="text" value={this.state.searchAccountId} onChange={this.searchAccountIdChange} />
                        </FormGroup>
                        <Button onClick={this.searchUser} disabled={!this.state.searchAccountId || this.state.isSearching} variant="primary">FIND</Button>
                    </div>
                    {!this.state.isSearching && this.state.foundUser && this.state.foundUser.userId && <>
                        <hr className="my-5" />
                        <div className="mb-3">
                            Account found: <b>{this.state.foundUser.accountId} {this.state.foundUser.accountName}</b>
                        </div>
                        <div className="mb-5">
                            <Button onClick={this.actAsUser} variant="primary"><Incognito size={18} style={{verticalAlign: "text-bottom"}} /> Act as this user</Button>
                        </div>
                    </>}

                    {!this.state.isSearching && this.state.foundUser && !this.state.foundUser.userId &&
                        <div className="mb-5">
                            <Alert variant="danger">Sorry, we couldn't find that account</Alert>
                        </div>
                    }
                </>}
            </div>
        );
    }
}

const RouterHeader = withRouter(ActAsUser);
export default connect(mapStateToProps)(RouterHeader);
