import { request } from '../functions/apiRequestWrapper';
import cookie from '../functions/cookie';
import { getCurrentCart, cartLogout } from './Cart';
import { savedOrdersLogout } from './SavedOrders';
import { historyLogout } from './OrderHistory';

const SET_PROFILE_DATA = "PROFILE/SET_PROFILE_DATA";
const UPDATE_PROFILE_DATA = "PROFILE/UPDATE_PROFILE_DATA";
const SET_IS_AUTHED = "PROFILE/SET_IS_AUTHED";
const SET_LOADING = "PROFILE/SET_LOADING";
const SET_INIT_DONE = "PROFILE/SET_INIT_DONE";
const SET_UPDATING = "PROFILE/SET_UPDATING";
const SET_ERROR = "PROFILE/SET_ERROR";
const LOGOUT = "PROFILE/LOGOUT";

export function fetchProfile(cb, errCb, auth) {
    return (dispatch, getState) => {
        dispatch({ type: SET_LOADING, payload: true });
        dispatch({ type: SET_ERROR, payload: null });

        return request(
            `${process.env.REACT_APP_API}/1/account/profile`,
            false,
            auth
        ).then((data) => {
            let newAuth = data.newAuth || false;
            dispatch(getCurrentCart(newAuth));
            delete data.newAuth;
            dispatch({ type: SET_PROFILE_DATA, payload: data });
            localStorage.setItem("user", JSON.stringify(data));
            dispatch({ type: SET_INIT_DONE, payload: true });
            if (typeof cb === 'function') { cb(); }
        }).catch((error) => {
            // console.error(error);
            switch (error.status) {
                case 404:
                case 401:
                    return dispatch(logout(error));
                default:
                    let cache = JSON.parse(localStorage.getItem("user"));
                    if (cache && cache.email) {
                        console.log("loading cached user");
                        dispatch({ type: SET_PROFILE_DATA, payload: cache });
                        dispatch({ type: SET_INIT_DONE, payload: true });
                        dispatch(getCurrentCart());
                    } else {
                        console.error('fetchProfile', error);
                        dispatch({ type: SET_LOADING, payload: false });
                        dispatch({ type: SET_INIT_DONE, payload: true });
                        if (typeof errCb === 'function') { errCb(error); }
                    }

            }
        });
    };
}

export function updateProfile(formData, cb, errCb) {
    return (dispatch, getState) => {
        dispatch({ type: SET_UPDATING, payload: true });
        return request(
            `${process.env.REACT_APP_API}/1/account/profile`,
            {
                method: "PATCH",
                body: JSON.stringify(formData),
            }
        ).then(() => {
            let oldData = getState().user.data;
            if (oldData.actingAsUser) {
                oldData.actingAsUser = Object.assign({}, oldData.actingAsUser, formData)
                dispatch({ type: UPDATE_PROFILE_DATA, payload: oldData });
                localStorage.setItem("user", JSON.stringify(oldData));
            } else {
                let newData = Object.assign({}, oldData, formData);
                dispatch({ type: UPDATE_PROFILE_DATA, payload: newData });
                localStorage.setItem("user", JSON.stringify(newData));
            }
            if (typeof cb === 'function') { cb(); }
        }).catch((error) => {
            console.error(error);
            dispatch({ type: SET_UPDATING, payload: false });
            if (typeof errCb === 'function') { errCb(error); }
        });
    }
}

export function logout(error) {
    return (dispatch, getState) => {
        let userData = getState().user.data;
        if (userData && userData.canActAsUser && userData.actingAsUser) {
          request(
              `${process.env.REACT_APP_API}/1/users/actasuser`,
              { method: 'DELETE', }
          ).then(() => {
              cookie.removeItem('auth', '/');
          }).catch((error) => {
              console.error(error);
              cookie.removeItem('auth', '/');
          });
        } else {
            cookie.removeItem('auth', '/');
        }
        // preserve offline saved carts on logout
        let offlineSaved = localStorage.getItem("savedCarts");
        localStorage.clear();
        if (offlineSaved) {
            localStorage.setItem("savedCarts", offlineSaved);
        }
        setTimeout(() => {
            dispatch({ type: LOGOUT, error: error });
            dispatch(cartLogout());
            dispatch(savedOrdersLogout());
            dispatch(historyLogout());
        }, 300);
    }
}

const defaultState = {
  data: null,
  isAuthed: false,
  isLoading: false,
  initLoadDone: false, // indicates if the app has attempted to load the profile from the session cookie on initial page load
  isUpdating: false,
  error: null,
}

export function userReducer(state = defaultState, action) {
  switch (action.type) {

    case SET_LOADING:
      return Object.assign({}, state, {
        isLoading: action.payload
      });

    case SET_INIT_DONE:
      return Object.assign({}, state, {
        initLoadDone: action.payload
      });

    case SET_UPDATING:
      return Object.assign({}, state, {
        isUpdating: action.payload
      });

    case SET_IS_AUTHED:
      return Object.assign({}, state, {
        isAuthed: action.payload
      });

    case SET_PROFILE_DATA:
      return Object.assign({}, state, {
        data: action.payload,
        isAuthed: true,
        isLoading: false
      });

    case UPDATE_PROFILE_DATA:
      return Object.assign({}, state, {
        data: action.payload,
        isUpdating: false
      });

    case SET_ERROR:
      return Object.assign({}, state, {
        error: action.payload
      });

    case LOGOUT:
      return Object.assign({}, defaultState, {
        initLoadDone: true,
        error: action.error || null
      });

    default:
      return state;
  }
}
