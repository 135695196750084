import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import BookStack from './BookStack';
import { X } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { PATH_SAVED_ORDER } from '../constants';
import { getValues } from '../functions/getValues';
import { formatDollar, getTotalItems } from '../functions/helpers';

const SavedOrderListItem = ({cart, offline, idx, syncOfflineOrder, deleteSavedOrder, appendSavedOrder, loadSavedOrder, goToCart}) => {

    var covers = cart && cart.items ? getValues(cart.items, 'imageUrl', 4) : [];

    const [confirm, setConfirm] = useState(false); // "delete", "replace"
    const [loading, setLoading] = useState(false); // "delete", "replace", "add", "sync"
    const [error, setError] = useState(false);
    
    const onDelete = () => {
        setConfirm(false);
        if (offline) {
            deleteSavedOrder(idx);
        } else {
            setLoading("delete");
            deleteSavedOrder(
                cart.id,
                () => { setLoading(false); },
                (err) => { setError(err); setLoading(false); }
            );
        }
    }
    
    // const onAdd = () => {
    //     setLoading("add");
    //     appendSavedOrder(
    //         cart.id,
    //         () => { setLoading(false); },
    //         (err) => { setError(err); setLoading(false); }
    //     );
    // }
    
    const onReplace = () => {
        setConfirm(false);
        setLoading("replace");
        loadSavedOrder(
            cart.id,
            () => { goToCart(); },
            (err) => { setError(err); setLoading(false); }
        );
    }
    
    const onSync = () => {
        setLoading("sync");
        syncOfflineOrder(
            idx,
            () => { setLoading(false); },
            (err) => { setError(err); setLoading(false); }
        );
    }

    return (
        <div className="col-6 col-sm-4 col-lg-3">

            <div className="row mx-0 flex-nowrap">
                <div className="col px-0">
                    <Link to={`${PATH_SAVED_ORDER}/${cart.id}`}>
                        <BookStack size="300" images={covers} />
                    </Link>
                </div>
                <div className="col flex-nowrap px-0 delete-control">
                    <button className="borderless-btn" onClick={() => { setConfirm("delete"); }} disabled={Boolean(loading)}><X /></button>
                </div>
            </div>

            <div className="cart-details pb-5">
                <div className="mb-3 saved-date">
                    {new Date(cart.when).toLocaleString('en-AU', { year: 'numeric', month: 'long', day: 'numeric' })}
                </div>
                <div>Total: {formatDollar(cart.totalAmount)}</div>
                <div>Titles: {cart.items.length}</div>
                <div>Total items: {getTotalItems(cart.items)}</div>
                <div>Account: {cart.user.accountId}</div>
                {!offline && <div>
                    <p className="mt-3">
                        <Button variant="primary" onClick={() => { appendSavedOrder(cart.id, goToCart); }} disabled={Boolean(loading)}>
                            {loading === "add" && <Spinner animation="border" size="sm" variant="light" />} Add to Basket
                        </Button>
                    </p>
                    <p className="mt-3">
                        <Button variant="outline-primary" onClick={() => { setConfirm("replace"); }} disabled={Boolean(loading)}>
                            {loading === "replace" && <Spinner animation="border" size="sm" variant="primary" />} Replace Basket
                        </Button>
                    </p>
                </div>}
                {offline && <div>
                    <p className="mt-3">
                        <Button variant="primary" onClick={onSync} disabled={Boolean(loading)}>
                            {loading === "sync" && <Spinner animation="border" size="sm" variant="light" />} Sync Order
                        </Button>
                    </p>
                </div>}
            </div>
            
            <Modal show={Boolean(confirm)}>
                {confirm === "delete" && <Modal.Body>
                    Delete saved order?
                </Modal.Body>}
                {confirm === "replace" && <Modal.Body>
                    Replace current basket?
                </Modal.Body>}
                <Modal.Footer>
                    {confirm === "delete" && <Button variant="primary" onClick={onDelete}>Delete</Button>}
                    {confirm === "replace" && <Button variant="primary" onClick={onReplace}>Replace</Button>}
                    <Button variant="outline-primary" onClick={() => { setConfirm(false); }}>Cancel</Button>
                </Modal.Footer>
            </Modal>
            
            <Alert variant="danger" className="fixedalert" dismissible onClose={() => { setError(false); }} show={Boolean(error)}><p className="my-2">{error}</p></Alert>
        </div>
    );
}

export default SavedOrderListItem;
