import React, { useState, useEffect } from 'react';
import BookListItem from '../../components/BookListItem';
import { connect } from 'react-redux';
import { clearCart, saveCart, exportPdf, exportCsv } from '../../redux/Cart';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import BookStack from '../../components/BookStack';
import { Download, X } from 'react-bootstrap-icons';
import { NavLink } from 'react-router-dom';
import { PATH_CHECKOUT, PATH_SAVED_ORDERS } from '../../constants';
import { getValues } from '../../functions/getValues';
import { countProductsReducer } from '../../functions/helpers';
import _findIndex from "lodash/findIndex";
import cn from "classnames";

const mapStateToProps = (state, ownProps) => {
    return {
        userData: state.user.data,
        isLoadingCart: state.cart.isLoadingCart,
        isDownloadingFile: state.cart.isDownloadingFile,
        error: state.cart.error,
        currentCart: state.cart.currentCart ? state.cart.currentCart : {},
        cartTotal: state.cart.currentCart && state.cart.currentCart.totalAmount ? state.cart.currentCart.totalAmount : 0,
        cartQty: state.cart.currentCart && state.cart.currentCart.items ? state.cart.currentCart.items.reduce(countProductsReducer, 0) : 0,
        cartCovers: state.cart.currentCart && state.cart.currentCart.items ? getValues(state.cart.currentCart.items, 'imageUrl', 4) : [],
    };
}

const mapDispatchToProps = dispatch => {
    return {
        clearCart: (cb) => { dispatch(clearCart(cb)); },
        saveCart: (cb) => { dispatch(saveCart(cb)); },
        exportPdf: () => { dispatch(exportPdf()); },
        exportCsv: () => { dispatch(exportCsv()); },
    };
}

const ViewCart = ({ userData, isLoadingCart, isDownloadingFile, currentCart, clearCart, saveCart, exportPdf, exportCsv, cartTotal, cartQty, cartCovers, history }) => {

    const [confirm, setConfirm] = useState(false);
    const [hasHardieGrant, setHasHardieGrant] = useState(false);
    
    useEffect(() => {
      // https://303lowe.atlassian.net/browse/HBR-107
      if (userData.country === "NZ") {
        let idx = _findIndex(currentCart.items, function (o) { return o.product?.publishingCompany === "HARDIE GRANT"; });
        setHasHardieGrant(idx > -1);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentCart.items]);

    return (
        <div className="page cart">

            {!(!isLoadingCart && cartQty!==0) &&
                <h2 className="text-center py-5">Your cart is empty.</h2>
            }

            {(cartQty!==0) && <div>
                <div className="downloads">
                    <Button
                        type="button"
                        variant="outline-primary"
                        className="mr-2 mb-2"
                        onClick={() => exportPdf()}
                        disabled={isLoadingCart || isDownloadingFile || (currentCart.items && currentCart.items.length <= 0)}>
                            <Download size={16} className="mr-1" />
                            Export to PDF
                    </Button>
                    <Button
                        type="button"
                        variant="outline-primary"
                        className="mr-2 mb-2"
                        onClick={() => exportCsv()}
                        disabled={isLoadingCart || isDownloadingFile || (currentCart.items && currentCart.items.length <= 0)}>
                            <Download size={16} className="mr-1" />
                            Export to CSV
                    </Button>
                </div>

                <div className="cart-header mb-5 mt-4">
                    <div className="row justify-content-center">
                        <div className="col-11 col-md-10">
                            <div className="row">

                                <div className="col-12 col-sm-5 col-md-4">
                                    <div className="pt-5 pt-md-0 book-cover">
                                        <div className="book-cover-wrapper">
                                            <BookStack size="300" images={cartCovers} />
                                        </div>
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="cart-details py-5 pl-md-5">
                                        <h1 className="mb-3">Basket total</h1>
                                        <div className="items-count mb-3">
                                            {currentCart.items && <span>{currentCart.items.length} titles<br /></span>}
                                            {cartQty} total items
                                            <button
                                                type="button"
                                                className="borderless-btn ml-3"
                                                onClick={() => setConfirm(true)}
                                                disabled={isLoadingCart || (currentCart.items && currentCart.items.length <= 0)}>
                                                    <X size={35} />
                                                    Clear Cart
                                            </button>
                                        </div>
                                        <div className="price mb-3">${cartTotal}</div>
                                        <hr className="my-5" />
                                        <Button
                                            type="button"
                                            variant="outline-primary"
                                            className="mr-2 mb-2"
                                            onClick={() => saveCart(() => { history.push(PATH_SAVED_ORDERS); })}
                                            disabled={isLoadingCart || (currentCart.items && currentCart.items.length <= 0)}>
                                            Save order for later
                                        </Button>
                                        <NavLink to={PATH_CHECKOUT} disabled={isLoadingCart || (currentCart.items && currentCart.items.length <= 0)}>
                                            <Button
                                                type="button"
                                                variant="primary"
                                                className="mr-2 mb-2"
                                                onClick={() => {}}
                                                disabled={isLoadingCart || (currentCart.items && currentCart.items.length <= 0)}>
                                                Checkout
                                            </Button>
                                        </NavLink>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                
                {hasHardieGrant && <p className="h5"><b>This portion of the order will be fullfilled by Harper Entertainment Distribution Services (HEDS)</b></p>}
                <div className={cn("row product-row", {"pt-5": !hasHardieGrant, "pt-3": hasHardieGrant})}>
                    {currentCart.items && currentCart.items.map((item, i) => {
                        if (hasHardieGrant && item.product.publishingCompany === "HARDIE GRANT") { return null; }
                        return (
                            <div key={item.productId} className="col-6 col-sm-4 col-md-3">
                                <BookListItem className="mb-5" isbn={item.productId} title={item.product.name} price={item.unitPrice} imageUrl={item.imageUrl} />
                            </div>
                        );
                    })}
                </div>
                
                {hasHardieGrant && <>
                  <p className="h5"><b>This portion of the order will be fullfilled by TDLC on behalf of Hardie Grant</b></p>
                  <div className="row product-row pt-3">
                      {currentCart.items && currentCart.items.map((item, i) => {
                          if (item.product.publishingCompany !== "HARDIE GRANT") { return null; }
                          return (
                              <div key={item.productId} className="col-6 col-sm-4 col-md-3">
                                  <BookListItem className="mb-5" isbn={item.productId} title={item.product.name} price={item.unitPrice} imageUrl={item.imageUrl} />
                              </div>
                          );
                      })}
                  </div>
                </>}
                
                <Modal show={confirm}>
                    <Modal.Body>
                        Clear cart?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => { clearCart(); setConfirm(false); }}>Clear</Button>
                        <Button variant="outline-primary" onClick={() => setConfirm(false)}>Cancel</Button>
                    </Modal.Footer>
                </Modal>

            </div>}
        </div>
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ViewCart)
