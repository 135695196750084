import React from 'react';
import { Helmet } from 'react-helmet';
import Breadcrumb from '../components/Breadcrumb';
import { JUICERID_AU, JUICERID_NZ } from '../constants';

import { connect } from 'react-redux';
const mapStateToProps = state => {
    return {
        country: state.user.data && state.user.data.country ? state.user.data.country : "AU"
    };
}

class News extends React.Component {

    componentDidMount() {
        setTimeout(() => {window.scrollTo(0, 0);}, 100);
    }

    render() {
        let juicerId = this.props.country === "NZ" ? JUICERID_NZ : JUICERID_AU;
        return (
            <div className="page news container px-3 px-md-5">
                <Helmet>
                    <script src='https://assets.juicer.io/embed.js' type='text/javascript'></script>
                    <link href='https://assets.juicer.io/embed.css' media='all' rel='stylesheet' type='text/css' />
                </Helmet>
                <Breadcrumb>News</Breadcrumb>
                <h2 className="mb-3">News</h2>
                <ul className="juicer-feed hhq-news" data-feed-id={juicerId} data-columns="4"></ul>
            </div>
        );
    }
}

export default connect(mapStateToProps)(News);
