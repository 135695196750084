import React from 'react';
import Loader from '../../components/Loader';
import BookListItem from '../../components/BookListItem';
import Pagination from '../../components/Pagination';
import SearchField from '../../components/SearchField';
import Button from 'react-bootstrap/Button';
import { ChevronUp, ChevronDown } from 'react-bootstrap-icons';
import AdvancedSearchForm from './AdvancedSearchForm';
import Breadcrumb from '../../components/Breadcrumb';

import { connect } from 'react-redux';
import { getBooks, setSort, setSearchType, clearAdvSearch } from '../../redux/Catalogue';
const mapStateToProps = state => {
    return {
        list: state.catalogue.list,
        count: state.catalogue.count,
        page: state.catalogue.page,
        pageSize: state.catalogue.pageSize,
        sortField: state.catalogue.sortField,
        sortDir: state.catalogue.sortDir,
        isLoading: state.catalogue.isLoading,
        error: state.catalogue.error,
        search: state.catalogue.search,
        advSearch: state.catalogue.advSearch,
        searchType: state.catalogue.searchType,
        triggerSearch: state.catalogue.triggerSearch
    };
}
const mapDispatchToProps = dispatch => {
    return {
        getBooks: (p, o, s) => { dispatch(getBooks(p, o, s)); },
        setSort: (f, d) => { dispatch(setSort(f, d)); },
        setSearchType: (t) => { dispatch(setSearchType(t)); },
        clearAdvSearch: () => { dispatch(clearAdvSearch()); }
    };
}

class Catalogue extends React.Component {

    componentDidMount() {
        setTimeout(() => {window.scrollTo(0, 0);}, 100);
        if (!this.props.list || this.props.list.length === 0) {
            this.props.getBooks();
        }
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.triggerSearch && !prevProps.triggerSearch) {
            this.props.getBooks();
        }
    }

    handlePaginationChange = (idx) => {
        setTimeout(() => {window.scrollTo(0, 0);}, 800);
        this.props.getBooks(idx + 1);
    }

    submitAdvSearch = (values, formikBag) => {
        let _values = {...values};
        // correct publish dates if needed
        if (values.publishDateFromYear && !values.publishDateFromMonth) {
            formikBag.setFieldValue("publishDateFromMonth", "01");
            _values.publishDateFromMonth = "01";
        }
        if (values.publishDateToYear && !values.publishDateToMonth) {
            formikBag.setFieldValue("publishDateToMonth", "01");
            _values.publishDateToMonth = "01";
        }
        if (values.publishDateFromMonth && !values.publishDateFromYear) {
            formikBag.setFieldValue("publishDateFromMonth", "");
            _values.publishDateFromMonth = "";
        }
        if (values.publishDateToMonth && !values.publishDateToYear) {
            formikBag.setFieldValue("publishDateToMonth", "");
            _values.publishDateToMonth = "";
        }
        // search
        this.props.getBooks(1, undefined, _values);
    }

    render() {
        const {list, isLoading, page, pageSize, sortField, sortDir, count, error} = this.props;
        return (
            <div className="page catalogue mb-5 container px-3 px-md-5">
                <Breadcrumb>Catalogue</Breadcrumb>
                <h1 className="mb-5" id="cataloguetitle">Catalogue</h1>

                {this.props.searchType === "simple" && <div className="row align-items-center">
                    <div className="col">
                        <SearchField onEnter={this.props.getBooks} className="mb-2" />
                    </div>
                    <div className="col-auto">
                        <Button variant="outline-primary" className="mb-2" onClick={() => { this.props.setSearchType("advanced"); document.getElementById("cataloguetitle").scrollIntoView(); }}>Advanced Search</Button>
                    </div>
                </div>}

                {this.props.searchType === "advanced" && <div>
                    <p className="text-right">
                        <Button variant="outline-primary" onClick={() => { this.props.setSearchType("simple"); }}>Simple Search</Button>
                    </p>
                    <AdvancedSearchForm onSubmit={this.submitAdvSearch} onClear={this.props.clearAdvSearch} values={this.props.advSearch} />
                </div>}

                <hr className="my-5" />

                <div className="row mb-5">
                    <div className="col-12 col-sm-auto">{count && count !== 0 ? <>Page {page} of {count} results</> : ''}</div>
                    <div className="col text-sm-right sorting">
                        <span className="mx-1">Sort by</span>
                        <Button size="sm"
                            variant={sortField === "title" ? "primary" : "secondary"}
                            onClick={() => {this.props.setSort("title", sortDir)}}>
                            Title
                        </Button>
                        <Button size="sm"
                            variant={sortField === "authorName" ? "primary" : "secondary"}
                            onClick={() => {this.props.setSort("authorName", sortDir)}}>
                            Author
                        </Button>
                        <Button size="sm"
                            variant={sortField === "publishDate" ? "primary" : "secondary"}
                            onClick={() => {this.props.setSort("publishDate", sortDir)}}>
                            Release date
                        </Button>
                        <div className="divider" />
                        {sortDir === "asc" && <Button size="sm"
                            variant="secondary"
                            onClick={() => {this.props.setSort(sortField, "desc")}}>
                            Ascending <ChevronDown size={16} />
                        </Button>}
                        {sortDir === "desc" && <Button size="sm"
                            variant="secondary"
                            onClick={() => {this.props.setSort(sortField, "asc")}}>
                            Descending <ChevronUp size={16} />
                        </Button>}
                    </div>
                </div>

                {isLoading && <Loader type={list && list.length > 0 ? "overlay" : "placeholder"} />}

                {(!isLoading && error) && <p className="alert alert-danger my-5">{error}</p>}

                {(!error && list && list.length > 0) && <div className="list books">
                    <div className="row product-row">
                        {list && list.map((product, i) => {
                            return (
                                <div key={product.isbn} className="col-6 col-sm-4 col-md-3">
                                    <BookListItem className="mb-5" {...product} showFullInfo />
                                </div>
                            );
                        })}
                    </div>
                </div>}

                {(count > pageSize) &&
                    <Pagination
                        totalSteps={Math.ceil(count / pageSize)}
                        activeIndex={page - 1}
                        onChange={this.handlePaginationChange}
                    />
                }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Catalogue);
