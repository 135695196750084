import classnames from 'classnames';
import { NavLink } from 'react-router-dom';
import { DateTime } from 'luxon';
import { PATH_RELEASES, PATH_CATALOGUE } from '../constants';
import BookCart from '../components/BookCart';
import coverFallback from '../nocover.png';
import { formatDollar } from '../functions/helpers';

const BookListItem = ({
    monthYear, orderFormId, publisher,
    isbn, title, price, authorName, publishDate, imageUrl, inStock, format,
    className,
    appendKey, showFullInfo
}) => {

    let linkTo = `${PATH_CATALOGUE}/${isbn}`;
    if (publisher && monthYear && orderFormId) {
      linkTo = `${PATH_RELEASES}/${monthYear}/publisher/${publisher}/${orderFormId}/${isbn}`;
    }
    else {
        if (monthYear && orderFormId) {
            linkTo = `${PATH_RELEASES}/${monthYear}/${orderFormId}/${isbn}`;
        }
    }

    return (
      <div className={classnames("book-list-item", className, {"full-info": showFullInfo})}>
            <NavLink to={linkTo}>
                {imageUrl &&
                    <img src={imageUrl.replace('x1000.', 'x180.')} onError={(e)=>{e.target.onerror = null; e.target.src = coverFallback;}} alt="book cover preview" className="d-block mb-2" />
                }
                {!imageUrl &&
                    <img src={coverFallback} alt="book cover preview" className="d-block mb-2" />
                }
                <p className="book-title mb-2">{title}</p>
            </NavLink>
            {showFullInfo && authorName && <p>By: {authorName}</p>}
            {showFullInfo && isbn && <p>ISBN: {isbn}</p>}
            {showFullInfo && publishDate && <p>Published: {DateTime.fromISO(publishDate).setLocale("en-AU").toLocaleString()}</p>}
            {showFullInfo && format && <p>Format: {format}</p>}
            {showFullInfo && inStock && <p>Status: {inStock}</p>}
            <p className="price">RRP: {formatDollar(price)}</p>
            <BookCart isbn={isbn} appendKey={appendKey} price={price} title={title} imageUrl={imageUrl} />
        </div>
    );
}

export default BookListItem;
