import React from 'react';
import { liveQuery } from "dexie";
import { db } from '../db';
import { request } from '../functions/apiRequestWrapper';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import { connect } from 'react-redux';
const mapStateToProps = state => {
    return {
        releases: state.releases.list,
        isLoadingReleases: state.releases.isLoading
    };
}

//https://github.com/dfahlander/Dexie.js/issues/1231#issuecomment-779723851
const observable = liveQuery (
    () => db.releases.orderBy("yyyymm").reverse().toArray()
);

class Download extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dbReleases: [],
            selectedRelease: null,
            downloadStarted: 0,
            downloadDone: 0,
            downloadFailedFetch: 0,
            downloadFailedDb: 0,
            downloadCovers: [],
            error: false
        }
    }

    componentDidMount() {
        this.subscription = observable.subscribe(
            result => this.setState({dbReleases: result}),
            error => this.setState({error, dbReleases: []})
        );
    }

    componentWillUnmount() {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = null;
        }
    }

    downloadRelease = () => {
        if (!this.state.selectedRelease || this.state.selectedRelease === null) {
            return;
        }
        let started = 1;
        let done = 0;
        let failedFetch = 0;
        let failedDb = 0;
        let covers = [];
        let months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
        this.setState({downloadStarted: started, downloadDone: done, downloadFailedFetch: failedFetch, downloadFailedDb: failedDb});
        let slug = this.state.selectedRelease;
        request(
            `${process.env.REACT_APP_API}/1/releases/${slug}`
        ).then((releaseData) => {
            let slugSplit = slug.split("-");
            let ym = slugSplit[1];
            let mnth = (months.indexOf(slugSplit[0]) + 1).toString().padStart(2, '0');
            ym += mnth;
            let release = {slug: releaseData.slug, catalogue: releaseData.catalogue, imageUrls: releaseData.imageUrls, coverImage: releaseData.coverImage, yyyymm: ym};
            db.releases.put(release).then(() => {
                done += 1; this.setState({downloadDone: done});
            }).catch(() => {
                failedDb += 1; this.setState({downloadFailedDb: failedDb});
            });

            // loop releaseData.sections
            let numSections = releaseData.sections ? releaseData.sections.length : 0;
            started += numSections; this.setState({downloadStarted: started});
            for (let s=0; s<numSections; s++) {
                let section = releaseData.sections[s];
                section.idx = s;
                // we only want to store a list of isbns on the section, not full products
                section.products = [];
                // we don't need download links when offline
                delete section.orderForm;
                delete section.catalogueOnixFull;
                delete section.catalogueOnixBasic;
                request(
                    `${process.env.REACT_APP_API}/1/releases/${release.slug}/${section.orderFormId}/products`
                    // eslint-disable-next-line
                ).then((sectionData) => {
                    // loop sectionData.products
                    // we do this before saving the section to get a list of isbns
                    let numProducts = sectionData.products ? sectionData.products.length : 0;
                    started += numProducts; this.setState({downloadStarted: started});
                    for (let p=0; p<numProducts; p++) {
                        let product = sectionData.products[p];
                        section.products.push(product.isbn);
                        request(
                            `${process.env.REACT_APP_API}/1/product/${product.isbn}`
                            // eslint-disable-next-line
                        ).then((bookData) => {
                            db.products.put(bookData).then(() => {
                                done += 1;
                                covers.push(bookData.imageUrl.replace('x1000.', 'x180.'));
                                this.setState({downloadDone: done, downloadCovers: covers});
                            }).catch(() => {
                                failedDb += 1; this.setState({downloadFailedDb: failedDb});
                            });
                            // eslint-disable-next-line
                        }).catch((error) => {
                            failedFetch += 1; this.setState({downloadFailedFetch: failedFetch});
                        });
                    }

                    // save section
                    db.sections.put(section).then(() => {
                        done += 1; this.setState({downloadDone: done});
                    }).catch(() => {
                        failedDb += 1; this.setState({downloadFailedDb: failedDb});
                    });
                    // eslint-disable-next-line
                }).catch((error) => {
                    failedFetch += 1; this.setState({downloadFailedFetch: failedFetch});
                });
            }
        }).catch((error) => {
            failedFetch += 1; this.setState({downloadFailedFetch: failedFetch});
        });
    }

    reset = () => {
        this.setState({
            downloadStarted: 0,
            downloadDone: 0,
            downloadFailedFetch: 0,
            downloadFailedDb: 0,
            downloadCovers: []
        });
    }

    deleteAll = () => {
        db.delete().then(() => db.open().then(() => {
            this.subscription = observable.subscribe(
                result => this.setState({dbReleases: result}),
                error => this.setState({error, dbReleases: []})
            );
        }));
    }

    render() {
        const {downloadStarted, downloadDone, downloadFailedDb, downloadFailedFetch, dbReleases, downloadCovers} = this.state;
        return (
            <div>
                <p className="mb-3">Use this form to download Release data for offline use.</p>
                <FormGroup>
                    <FormLabel>Select release</FormLabel>
                    <FormControl
                        as="select"
                        value={this.state.selectedRelease || ""}
                        onChange={(e) => {
                            this.setState({selectedRelease: e.target.value})
                        }}
                    >
                        <option value=""></option>
                        {this.props.releases.map((r, i) => {
                            return (<option value={r.slug} key={i}>{r.catalogue}</option>);
                        })}
                    </FormControl>
                </FormGroup>

                {downloadStarted < 1 && <p><Button onClick={this.downloadRelease}>Start</Button></p>}

                {downloadStarted > 0 && <div>
                    <p>Completed: {downloadDone + downloadFailedDb + downloadFailedFetch} / {downloadStarted}</p>
                    {(downloadFailedDb > 0 || downloadFailedFetch > 0) && <p className="text-success">Success: {downloadDone} / {downloadStarted}</p>}
                    {downloadFailedFetch > 0 && <p className="text-danger">Failed to fetch: {downloadFailedFetch} / {downloadStarted}</p>}
                    {downloadFailedDb > 0 && <p className="text-danger">Failed to save: {downloadFailedDb} / {downloadStarted}</p>}
                    <p className="download-covers d-flex flex-wrap">
                        {downloadCovers && downloadCovers.length > 0 &&
                            downloadCovers.map((img, i) => <img src={img} alt="" />)
                        }
                    </p>
                </div>}

                {downloadStarted > 0 && (downloadDone + downloadFailedDb + downloadFailedFetch) === downloadStarted && <p><Button onClick={this.reset}>Download another release</Button></p>}

                <hr />

                <h5>Saved releases</h5>
                {(!dbReleases || dbReleases.length === 0) && <p>No releases saved</p>}
                {dbReleases && dbReleases.length >= 3 && <Alert variant="warning">
                    <p>Please note, the amount of space available for offline storage is limited by your browser, your device, and the amount of free space available.</p>
                    <p>Although it is theoretically possible to store as many releases as you want, we recommend 3 or less.</p>
                </Alert>}
                {dbReleases && dbReleases.map((r, i) => {
                    return (
                        <p key={r.slug}>{r.catalogue}</p>
                    );
                })}
                {dbReleases && dbReleases.length > 0 && <p><Button onClick={this.deleteAll}>Delete all</Button></p>}
            </div>
        );
    }
}

export default connect(mapStateToProps)(Download);
