import React from 'react';
import { withRouter } from 'react-router';
import { request } from '../functions/apiRequestWrapper';
import Breadcrumb from '../components/Breadcrumb';
import Banner from '../components/Banner';
import Loader from '../components/Loader';

import { connect } from 'react-redux';
import { logout } from '../redux/User';
const mapDispatchToProps = dispatch => {
    return {
        logout: () => { dispatch(logout()); }
    }
}

class ContentPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            page: {}
        };
    }

    componentDidMount() {
        setTimeout(() => {window.scrollTo(0, 0);}, 100);
        const {match} = this.props;
        request(
            `${process.env.REACT_APP_API}/1/cms/contentpage/${match.params.slug}`
        ).then((data) => {
            // console.log(data);
            this.setState({page: data, isLoading: false});
        }).catch((error) => {
            if (error.status === 401) {
                this.props.logout();
            } else {
                let page = {
                    title: "The page could not be loaded",
                    bodyContent: "<p>Sorry, we couldn't load that page.</p><p>Please note that some pages may not work if you are offline.</p>"
                };
                if (error.status === 404) {
                    page = {
                        title: "404 Not Found",
                        bodyContent: "<p>Sorry, we couldn't find that page.</p>"
                    };
                }
                this.setState({page: page, isLoading: false});
            }
        });
    }

    render() {
        const {page, isLoading} = this.state;
        return (
            <div className="page container px-3 px-md-5">
                {page.title && <>
                    <Breadcrumb>{page.title}</Breadcrumb>
                    <Banner imageUrl={page.imageUrl ? page.imageUrl : false}><h1>{page.title}</h1></Banner>
                </>}
                {page.bodyContent && <div dangerouslySetInnerHTML={{__html: page.bodyContent}} className="mb-5" />}
                {isLoading && <Loader type="placeholder" />}
            </div>
        );
    }
}

const routerContentPage = withRouter(ContentPage);
export default connect(undefined, mapDispatchToProps)(routerContentPage);
