import { request } from '../functions/apiRequestWrapper';
import { logout } from './User';
import { db } from '../db';

const SET_LIST = "REL/SET_LIST";
const CLEAR_LIST = "REL/CLEAR_LIST";
const SET_PAGE = "REL/SET_PAGE";
const SET_LOADING = "REL/SET_LOADING";
const SET_ERROR = "REL/SET_ERROR";

export function getReleases(_page) {
    return (dispatch, getState) => {
        dispatch({ type: SET_LOADING, payload: true });
        dispatch({ type: SET_ERROR, payload: null });
        const releases = getState().releases;
        let currentPage = releases.page;
        if (_page && typeof _page !== "undefined") {
            currentPage = _page;
            dispatch({type: SET_PAGE, payload: _page});
        }
        let skip = currentPage > 1 ? releases.pageSize * (currentPage - 1) : 0;
        let q = `?$top=${releases.pageSize}&$skip=${skip}&$count=true&$orderby=startDate desc`;
        request(
            `${process.env.REACT_APP_API}/1/releases${q}`
        ).then((data) => {
            let newList = data.items;
            if (currentPage > 1) {
                let oldList = releases.list;
                newList = oldList.concat(data.items);
            }
            dispatch({ type: SET_LIST, payload: newList, count: data.count });
        }).catch((error) => {
            if (error.status === 401) {
                return dispatch(logout());
            }
            let msg = "Could not reach the server.";
            if (error.body && error.body.message) {
                msg = error.body.message;
            }
            db.releases.orderBy("yyyymm").reverse().toArray().then((dbR) => {
                if (dbR && dbR.length > 0) {
                    return dispatch({ type: SET_LIST, payload: dbR, count: dbR.length });
                } else {
                    dispatch({ type: SET_LOADING, payload: false });
                    dispatch({ type: SET_ERROR, payload: msg });
                }
            }).catch(() => {
                dispatch({ type: SET_LOADING, payload: false });
                dispatch({ type: SET_ERROR, payload: msg });
            });
        });
    }
}

export function clearList() {
    return {type: CLEAR_LIST};
}

const defaultState = {
    list: [],
    count: 0,
    page: 1,
    pageSize: 12,
    isLoading: false,
    error: null,
};

export function releasesReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_LIST:
        return Object.assign({}, state, {
            list: action.payload,
            count: action.count,
            isLoading: false,
            error: null
        });
        case CLEAR_LIST:
        return Object.assign({}, state, {
            list: [],
            count: 0,
            page: 1,
            error: null
        });
        case SET_PAGE:
        return Object.assign({}, state, {
            page: action.payload
        });
        case SET_LOADING:
        return Object.assign({}, state, {
            isLoading: action.payload
        });
        case SET_ERROR:
        return Object.assign({}, state, {
            error: action.payload
        });

        default:
        return state;
    }
}
