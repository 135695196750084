import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import FormField from '../../../components/FormField';
import Loader from '../../../components/Loader';
import Button from 'react-bootstrap/Button';
import { request } from '../../../functions/apiRequestWrapper';

import {
  PATH_HOME,
  PATH_FORGOT_PASS
} from '../../../constants';

class LoginForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      submitAttempted: false,
      serverError: false
    }
  }

  login = (values, formikBag) => {
    var s = "AU";
    if (window?.location.hostname.indexOf(".co.nz") > -1) { s = "NZ"; }
    var payload = {...values, source: s};
    request(
      `${process.env.REACT_APP_API}/1/account/token`,
      {
        method: 'POST',
        body: JSON.stringify(payload),
      }
    ).then((authData) => {
      // authentication success, load profile
      this.props.fetchProfile(
        this.props.onLoadProfile,
        (error) => {
          this.handleServerError(error, formikBag);
        },
        authData
      );
    }).catch((error) => {
      this.handleServerError(error, formikBag);
    });
  }

  handleServerError = (error, formikBag) => {
      let msg = "An error has occured";
      if (error.body && error.body.modelState) {
        formikBag.setErrors(error.body.modelState);
        msg = false;
      } else if (error.body && error.body.message) {
        msg = error.body.message;
      }
      formikBag.setSubmitting(false);
      this.setState({serverError: msg});
  }

  validateForm = (values) => {
    // TODO
    return {};
  }

  render() {
    return (
      <Formik validate={this.validateForm} onSubmit={this.login} initialValues={this.props.initialValues}>
        {(formikBag) => (
          <Form className="p-5 position-relative">

            {formikBag.isSubmitting && <Loader />}

            <h3 className="mb-4">Login to your account</h3>

            <FormField id="username" label="Email address or Customer ID" formikBag={formikBag} onChange={this.props.onChangeUsername} />
            <FormField id="password" type="password" label="Password" formikBag={formikBag} />

            <p className="">
              <Link to={{pathname: PATH_FORGOT_PASS}}>Forgot your password?</Link>
            </p>

            <div className="text-right mt-4">
              <Button type="submit" variant="primary">Login</Button>
            </div>

            {this.state.serverError && <p className="server-error text-danger py-3">{this.state.serverError}</p>}
          </Form>
        )}
      </Formik>
    );
  }
}

LoginForm.propTypes = {
  onChangeUsername: PropTypes.func,
  initialValues: PropTypes.object,
  initialStep: PropTypes.number,
  goTo: PropTypes.func,
  fetchProfile: PropTypes.func,
  onLoadProfile: PropTypes.func,
  from: PropTypes.any
}

LoginForm.defaultProps = {
  from: { pathname: PATH_HOME }
}

export default LoginForm;
