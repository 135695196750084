import React from 'react';
import Breadcrumb from '../components/Breadcrumb';
import Loader from '../components/Loader';
import DownloadS3Button from '../components/DownloadS3Button';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import FormControl from 'react-bootstrap/FormControl';
import { request } from '../functions/apiRequestWrapper';

const months = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];

class Invoices extends React.Component {
  
    constructor(props) {
        super(props);
        this.state = {
            isLoadingInv: true,
            isLoadingStm: true,
            invoices: [],
            statements: [],
            invoiceYears: [],
            statementYears: [],
            activeInvoiceYear: "",
            activeInvoiceMonth: "january",
            activeStatementYear: "",
            error: null
        };
    }

    componentDidMount() {
        setTimeout(() => {window.scrollTo(0, 0);}, 100);
        request(
            `${process.env.REACT_APP_API}/1/billing/invoices`
        ).then((data) => {
            // console.log(data);
            let yrs = [];
            for (let i=0; i<data.length; i++) {
              yrs.push(Object.keys(data[i])[0]);
            }
            let month = this.getActiveInvoiceMonth(yrs[0], data);
            this.setState({ invoiceYears: yrs, activeInvoiceYear: yrs[0], activeInvoiceMonth: month, invoices: data, isLoadingInv: false });
        }).catch((error) => {
            console.error(error);
            if (error.status === 401) { this.props.logout(); }
            let msg = "An error has occured";
            if (error.body && error.body.message) {
                msg = error.body.message;
            }
            this.setState({error: msg, isLoadingInv: false});
        });
        request(
            `${process.env.REACT_APP_API}/1/billing/statements`
        ).then((data) => {
            // console.log(data);
            let yrs = [];
            for (let i=0; i<data.length; i++) {
              yrs.push(Object.keys(data[i])[0]);
            }
            this.setState({ statementYears: yrs, activeStatementYear: yrs[0], statements: data, isLoadingStm: false });
        }).catch((error) => {
            console.error(error);
            if (error.status === 401) { this.props.logout(); }
            let msg = "An error has occured";
            if (error.body && error.body.message) {
                msg = error.body.message;
            }
            this.setState({error: msg, isLoadingStm: false});
        });
    }
    
    getActiveInvoiceMonth = (year, inv) => {
      console.log("getActiveInvoiceMonth", year);
      const { invoices } = this.state;
      let _invoices = inv || invoices;
      let m = "january";
      let invMonths = null;
      for (let i=0; i<_invoices.length; i++) {
        console.log(_invoices[i]);
        if (_invoices[i][year]) {
          invMonths = _invoices[i][year];
          break;
        }
      }
      if (invMonths !== null) {
        for (let i=0; i<12; i++) {
          if (invMonths[months[i]]) {
            m = months[i];
            break;
          }
        }
      }
      return m;
    }

    render() {
        const {invoices, statements, invoiceYears, statementYears, activeInvoiceYear, activeInvoiceMonth, activeStatementYear, isLoadingStm, isLoadingInv, error} = this.state;
        let isLoading = isLoadingInv || isLoadingStm;
        return (
            <div className="page invoices container px-3 px-md-5">
                <Breadcrumb>HEDS Invoices & Statements</Breadcrumb>
                {(!isLoading && error) && <p className="alert alert-danger my-5">{error}</p>}
                <div className="position-relative">
                    {isLoading && <Loader type="placeholder" />}
                    
                    <div className="row">
                      <div className="col-12 col-md-6 mb-5 order-2 order-md-1">
                        {!isLoading && <div className="py-3">
                          <div className="invoices__heading mb-3">
                            <h2>Invoices</h2>
                            <FormControl as="select"
                              size="sm"
                              value={activeInvoiceYear}
                              onChange={(e) => {
                                let year = e.target.value;
                                let month = this.getActiveInvoiceMonth(year);
                                this.setState({activeInvoiceYear: year, activeInvoiceMonth: month});
                              }}
                            >
                              {invoiceYears?.map((opt, i) => (<option value={opt} key={i}>{opt}</option>))}
                            </FormControl>
                          </div>
                          {isLoadingInv && !isLoadingStm && <Loader type="placeholder" />}
                          {invoices && invoices.map(invYear => {
                            let year = Object.keys(invYear)[0];
                            if (year !== activeInvoiceYear) {
                              return null;
                            }
                            let invMonths = invYear[year];
                            return (
                              <Tab.Container key={`invyear${year}`} activeKey={activeInvoiceMonth} onSelect={(k) => { this.setState({activeInvoiceMonth: k}); }}>
                                <div className="row">
                                  <div className="col-sm-4">
                                    <Nav variant="pills" className="flex-column d-none d-sm-flex">
                                      {months.map(month => {
                                        return (
                                          <Nav.Link as="button" type="button" className="btn text-left text-capitalize" eventKey={month} key={`select${month}`}>{month} {invMonths[month] ? "("+invMonths[month].length+")" : ""}</Nav.Link>
                                        );
                                      })}
                                    </Nav>
                                    <DropdownButton className="d-sm-none mb-5 invoices__months" title={activeInvoiceMonth}>
                                      {months.map(month => {
                                        return (
                                          <Dropdown.Item as="button" type="button" className="text-left text-capitalize" eventKey={month} key={`select${month}`}>{month} {invMonths[month] ? "("+invMonths[month].length+")" : ""}</Dropdown.Item>
                                        );
                                      })}
                                    </DropdownButton>
                                  </div>
                                  <div className="col-sm-8">
                                    <Tab.Content>
                                      {months.map(month => {
                                        let invMonth = invMonths[month];
                                        if (!invMonth) {
                                          return null;
                                        }
                                        return (
                                          <Tab.Pane eventKey={month} key={`inv${year}${month}`} className="mb-4">
                                            {invMonth.map(item => {
                                              return (
                                                <p key={`inv${item.id}`}>
                                                  <DownloadS3Button method="GET" uri={`${process.env.REACT_APP_API}/1/billing/invoice/${item.file}`} file={item.file} label={`${item.id}_${item.sortDate}.pdf`} />
                                                </p>
                                              );
                                            })}
                                          </Tab.Pane>
                                        );
                                      })}
                                    </Tab.Content>
                                  </div>
                                </div>
                              </Tab.Container>
                            );
                          })}
                        </div>}
                      </div>
                      
                      <div className="col-12 col-md-6 mb-5 order-1 order-md-2">
                        {!isLoading && <div className="bg-light p-3">
                          <div className="invoices__heading mb-3">
                            <h2>Statements</h2>
                            <FormControl as="select"
                              size="sm"
                              value={activeStatementYear}
                              onChange={(e) => {
                                this.setState({activeStatementYear: e.target.value});
                              }}
                            >
                              {statementYears?.map((opt, i) => (<option value={opt} key={i}>{opt}</option>))}
                            </FormControl>
                          </div>
                          {isLoadingStm && !isLoadingInv && <Loader type="placeholder" />}
                          {statements && statements.map(stmYear => {
                            let year = Object.keys(stmYear)[0];
                            if (year !== activeStatementYear) {
                              return null;
                            }
                            let stmMonths = stmYear[year];
                            return (
                              <div>
                                {stmMonths.map(stm => {
                                  return (
                                    <p key={`stm${stm.month}`}>
                                      <DownloadS3Button method="GET" uri={`${process.env.REACT_APP_API}/1/billing/statement/${stm.file}`} file={stm.file} label={`${stm.month}${stm.year}.pdf`} />
                                    </p>
                                  );
                                })}
                              </div>
                            );
                          })}
                        </div>}
                      </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Invoices;
