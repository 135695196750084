import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _debounce from 'lodash/debounce';
import _findIndex from 'lodash/findIndex';
import BookListItem from '../BookListItem';
import SectionListItem from '../SectionListItem';
import SectionListPublisherItem from '../SectionListPublisherItem';
import { NavLink } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import './books-nav.scss';

const SCROLL_THRESHOLD = 50; // in px; How far to scroll, before paddles are visible.
const SCROLL_PADDING = 50; // in px;
const SCROLL_EASING = 0.2;

class BooksNav extends React.Component {

    constructor(props) {
        super(props);
        this.itemsRef = [];
        this.containerRef = React.createRef();
        this.panelRef = React.createRef();
        this.indicatorRef = React.createRef();
        this.topRef = React.createRef();
        this._debouncedUpdate = _debounce(this.update, 400);
        this._debouncedScroll = _debounce(this.updatePaddleVisibility, 400);
        this.state = {
            sticky: false,
            leftPaddle: false,
            rightPaddle: false
        }
    }

    componentDidMount() {
        // layout update on resize
        setTimeout(this.update, 1500);
        window.addEventListener("resize", this._debouncedUpdate, false);
        if (this.props.useSticky) {
            // check if the component is at the top of the screen, enables sticky styles
            this.topObserver = new IntersectionObserver((entries) => {
                this.setState({ sticky: !entries[0]['isIntersecting'] });
            }, { threshold: [0, 1] });
            this.topObserver.observe(this.topRef.current);
        }
        // scroll to initial selected item, if there is one
        if (this.props.activeId) {
            let activeIdx = 0;
            if (this.props.type === "book") {
                activeIdx = _findIndex(this.props.actions, {isbn: parseInt(this.props.activeId)});
            } else if (this.props.type === "section") {
                activeIdx = _findIndex(this.props.actions, {orderFormId: parseInt(this.props.activeId)});
            } else {
                activeIdx = _findIndex(this.props.actions, {to: this.props.activeId});
            }
            let activeTarget = this.itemsRef[activeIdx];
            if (activeTarget) {
                let rectTarget = activeTarget.getBoundingClientRect();
                let containerBounds = this.containerRef.current.getBoundingClientRect();
                let newLeft = rectTarget.left - containerBounds.left;
                if (newLeft > 50) { newLeft = newLeft - 50; }
                this.panelRef.current.scrollLeft = newLeft;
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.activeId !== this.props.activeId || prevState.sticky !== this.state.sticky) {
            this.updateIndicatorPosition();
        }
        if (prevState.sticky !== this.state.sticky) {
            this._debouncedUpdate();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this._debouncedUpdate, false);
        if (this.props.useSticky) {
            this.topObserver.unobserve(this.topRef.current);
        }
    }

  update = () => {
    this.updateIndicatorPosition();
    this.updatePaddleVisibility();
  }

  scroll = (dir) => {
    let containerBounds = this.containerRef.current.getBoundingClientRect();
    let elementBounds;
    let left = 0;
    let right = this.panelRef.current.offsetWidth;
    let scroll = 0;
    if (dir < 0) {
      // Scroll left
      for (let i = this.itemsRef.length - 1; i > 0; i--) {
        let element = this.itemsRef[i];
        elementBounds = element.getBoundingClientRect();
        let elementLeft = elementBounds.left - containerBounds.left;
        if (elementLeft < left) {
          scroll = Math.ceil(elementLeft + this.panelRef.current.scrollLeft - SCROLL_PADDING);
          break;
        }
      }
    } else {
      // Scroll right
      for (let i = 0; i < this.itemsRef.length; i++) {
        let element = this.itemsRef[i];
        elementBounds = element.getBoundingClientRect();
        let elementRight = elementBounds.left - containerBounds.left + element.offsetWidth;
        if (elementRight > right) {
          scroll = Math.ceil(elementRight + this.panelRef.current.scrollLeft + SCROLL_PADDING - right);
          break;
        }
      }
    }
    this.animateScroll(scroll);
  }

  animateScroll = (scroll) => {
    this._scrollStart = this.panelRef.current.scrollLeft;
    this._scrollDelta = scroll - this._scrollStart;
    this._scrollRatio = 0;
    this._scrollActive = true;
    window.requestAnimationFrame(() => this.animateScrollTick());
  }

  animateScrollTick = () => {
    let ratio = 1 - this._scrollRatio;
    ratio *= SCROLL_EASING;
    this._scrollRatio += ratio;
    if (ratio < 0.001) {
      this._scrollRatio = 1;
    }
    this.panelRef.current.scrollLeft = this._scrollStart + this._scrollDelta * this._scrollRatio;
    if (this._scrollActive && this._scrollRatio !== 1) {
      window.requestAnimationFrame(() => this.animateScrollTick());
    } else {
      this.updatePaddleVisibility();
    }
  }

  updatePaddleVisibility = () => {
    if (this.panelRef.current) {
      if (this.panelRef.current.scrollLeft - SCROLL_THRESHOLD > 0) {
        this.setState({ leftPaddle: true });
       } else {
        this.setState({ leftPaddle: false });
       }
      if (this.panelRef.current.scrollLeft + this.containerRef.current.offsetWidth + SCROLL_THRESHOLD < this.panelRef.current.scrollWidth) {
        this.setState({ rightPaddle: true });
      } else {
        this.setState({ rightPaddle: false });
      }
    }
  }

  updateIndicatorPosition = () => {
    let activeIdx = -1;
    if (this.props.type === "book") {
        activeIdx = _findIndex(this.props.actions, {isbn: this.props.activeId});
    } else if (this.props.type === "section") {
        activeIdx = _findIndex(this.props.actions, {orderFormId: parseInt(this.props.activeId)});
    } else {
        activeIdx = _findIndex(this.props.actions, {to: this.props.activeId});
    }
    if (activeIdx !== -1) {
      let activeTarget = this.itemsRef[activeIdx];
      if (activeTarget) {
        let containerBounds = this.containerRef.current.getBoundingClientRect();
        let rectTarget = activeTarget.getBoundingClientRect();
        let indicatorLeft = rectTarget.left - containerBounds.left + this.panelRef.current.scrollLeft;
        let indicatorWidth = activeTarget.offsetWidth;
        this.indicatorRef.current.style.left = `${indicatorLeft}px`;
        this.indicatorRef.current.style.width = `${indicatorWidth}px`;
        if (rectTarget.left > containerBounds.width || rectTarget.left < 50) {
          let newLeft = rectTarget.left - containerBounds.left + this.panelRef.current.scrollLeft;
          if (newLeft > 50) { newLeft = newLeft - 50; }
          this.panelRef.current.scrollLeft = newLeft;
        }
      }
    } else {
      this.indicatorRef.current.style.width = '0';
    }
  }

  render() {
    const {actions, type, monthYear, publisher, orderFormId, activeId} = this.props;
    return(<>
        <div ref={this.topRef} className="books-nav-top" />
      <nav ref={this.containerRef} className={classnames("books-nav mb-5", {"none-selected": !activeId, "use-sticky": this.props.useSticky, "sticky": this.state.sticky})}>
        <div ref={this.panelRef} onScroll={this._debouncedScroll} className="books-nav__panel">
          <ul className="books-nav__items">
            {type === "section" && actions.map((section, i) => {
              return (
                <li key={`${section.orderFormId}_${section.slug}`} className="books-nav__item section" ref={(el) => this.itemsRef[i] = el}>
                      {!(section.slug !== 0 && section.slug != null) &&
                          <SectionListItem monthYear={monthYear} className="books-nav__action" {...section} />
                      }
                </li>
              );
            })}
            {type === "section-publisher" && actions.map((section, i) => {
                return (
                    <li key={`${section.orderFormId}_${section.slug}`} className="books-nav__item section" ref={(el) => this.itemsRef[i] = el}>
                        {!(section.slug !== 0 && section.slug != null) &&
                            <SectionListPublisherItem monthYear={monthYear} publisher={publisher} className="books-nav__action" {...section} />
                        }
                    </li>
                );
            })}
            {type === "book" && actions.map((book, i) => {
              return (
                <li key={`${book.isbn}`} className="books-nav__item book" ref={(el) => this.itemsRef[i] = el}>
                  <BookListItem monthYear={monthYear} orderFormId={orderFormId} publisher={publisher} className="books-nav__action" {...book} />
                </li>
              );
            })}
            {type === "link" && actions.map((link, i) => {
              return (
                <li key={`link${i}`} className="books-nav__item link" ref={(el) => this.itemsRef[i] = el}>
                  <NavLink to={link.to} className="books-nav__action">{link.label}</NavLink>
                </li>
              );
            })}
          </ul>
          <span ref={this.indicatorRef} className="books-nav__indicator"></span>
        </div>
        <div className="books-nav__paddles">
          {this.state.leftPaddle &&
            <button type="button" className="books-nav__paddle-left" onClick={(e) => { this.scroll(-1); e.preventDefault(); }}>
              <ChevronLeft size={16} />
            </button>
          }
          {this.state.rightPaddle &&
            <button type="button" className="books-nav__paddle-right" onClick={(e) => { this.scroll(1); e.preventDefault(); }}>
              <ChevronRight size={16} />
            </button>
          }
        </div>
      </nav>
    </>);
  }

}

BooksNav.defaultProps = {
    actions: [],
    type: "link"
};

BooksNav.propTypes = {
  actions: PropTypes.array,
  type: PropTypes.oneOf(["book", "section", "link", "section-publisher"]),
  activeId: PropTypes.string,
  monthYear: PropTypes.string,
  orderFormId: PropTypes.string,
  onClick: PropTypes.func
};

export default BooksNav;
