import { Link } from 'react-router-dom';
import {
    PATH_HOME
} from '../../constants';
import './breadcrumb.scss';

const Breadcrumb = ({crumbs, children}) => {

    return (
        <nav className="hqbreadcrumb text-capitalize mb-4">
            <ul>
                <li><Link to={PATH_HOME}>Home</Link></li>
                {crumbs && crumbs.map((crumb, i) => {
                    return (
                        <li key={`crumb${i}`}><Link to={crumb.link}>{crumb.label}</Link></li>
                    );
                })}
                <li>{children}</li>
            </ul>
        </nav>
    );
}

export default Breadcrumb;
