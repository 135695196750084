import { Link } from 'react-router-dom';
import BookStack from './BookStack';
import { PATH_ORDER } from '../constants';
import { formatDollar } from '../functions/helpers';
import { DateTime } from 'luxon';

const OrderListItem = ({order, thisUser}) => {

    return (
        <Link to={`${PATH_ORDER}/${order.orderNumber}`} className="order-list-item mb-5 d-block">
            {order.imageUrls && <BookStack images={order.imageUrls} />}
            <div className="order-summary">

                <h5 className="mb-3">{DateTime.fromISO(order.createDate).toLocaleString({day: 'numeric', month: 'long', year: 'numeric'})}</h5>

                {(order.extraAccountId === 0 || order.extraAccountId === null || order.extraAccountId === thisUser) &&
                    <p className="mb-3 order-status">Standard order</p>
                }

                {!(order.extraAccountId === 0 || order.extraAccountId === null) && order.accountId.toString() !== thisUser &&
                    <p className="mb-3 order-status">I made this order for account {order.accountId}</p>
                }

                {!(order.extraAccountId === 0 || order.extraAccountId === null) && order.accountId.toString() === thisUser && order.extraAccountId !== thisUser &&
                    <p className="mb-3 order-status">Account {order.extraAccountId} made this order on my behalf</p>
                }

                <p className="mb-1">Total: {formatDollar(order.amount)}</p>
                <p className="mb-1">Titles: {order.items}</p>
                <p className="mb-1">Order: {order.orderNumber}</p>
                <p className="mb-1">Reference: {order.reference}</p>

            </div>
        </Link>
    );
}
export default OrderListItem;
