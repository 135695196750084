import classnames from "classnames";
import './book-stack.scss';
import coverFallback from '../../nocover.png';

const BookStack = (props) => {

    return (
        <div style={{maxWidth: `calc(${props.size}px + 15%)`}} className={classnames("book-stack", props.className, {"animate": props.images && props.images.length > 1})}>
            <div className="inner">
                {props.images && props.images.length > 0 && props.images.slice(0, 4).map((img, i) => {
                    if (img && img !== null) {
                        let uri = props.size ? img.replace('x1000.', `x${props.size}.`) : img;
                        return (
                            <img src={uri} alt="book cover preview" onError={(e)=>{e.target.onerror = null; e.target.src = coverFallback;}} key={i} />
                        );
                    }
                    return null;
                })}
                {(!props.images || props.images.length === 0) && <img src={coverFallback} alt="book cover preview" />}
            </div>
        </div>
    );
}

BookStack.defaultProps = {
    images: [],
    size: 180,
};

export default BookStack;
