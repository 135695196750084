import { useState, useEffect, useRef } from "react";
import SimpleReactLightbox, { SRLWrapper, useLightbox } from "simple-react-lightbox";
import coverFallback from '../../nocover.png';
import { request } from '../../functions/apiRequestWrapper';

const lbOptions = {
  buttons: {
    showDownloadButton: false,
    showFullscreenButton: false,
    showThumbnailsButton: false,
    showAutoplayButton: false,
  }
};

const InternalPreview = ({ images, buttonText }) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const timeoutRef = useRef(null);
  const { openLightbox } = useLightbox();
  
  const onFirstImgLoad = () => {
    // console.log("image loaded");
    setLoading(false);
    clearTimeout(timeoutRef.current);
  }
  const onFirstImgError = () => {
    // console.log("image error");
    setHasError(true);
    setLoading(false);
    clearTimeout(timeoutRef.current);
  }
  
  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      console.log("image timeout", buttonText);
      setHasError(true);
      setLoading(false);
      clearTimeout(timeoutRef.current);
    }, 10000);
    return () => {
      clearTimeout(timeoutRef.current);
    }
    // eslint-disable-next-line
  }, []);
  
  return (
    <>
    <button type="button" className="btn btn-primary mr-2 mb-2" onClick={openLightbox} style={loading || hasError ? {display: "none"} : {}}>
        {buttonText}
    </button>
    <div style={{ display: 'none' }}>
    <SRLWrapper options={lbOptions}>
        {images.map(function (img, i) {
          if (i === 0) {
            return <img key={i} src={img} alt="" onLoad={onFirstImgLoad} onError={onFirstImgError} />;
          }
          return <img key={i} src={img} alt="" />;
        })}
    </SRLWrapper>
    </div>
    </>
  );
}

const CoverPreview = ({ imageUrl }) => {
  const { openLightbox } = useLightbox();
  return (
    <>
    <img src={imageUrl} onError={(e)=>{e.target.onerror = null; e.target.src = coverFallback;}} alt="book cover preview" onClick={openLightbox} />
    <div style={{ display: 'none' }}>
    <SRLWrapper options={lbOptions}>
        <img src={imageUrl} alt="Cover" />
    </SRLWrapper>
    </div>
    </>
  );
}

const CombinedPreview = ({ imageUrl, images, onClickVideo }) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const timeoutRef = useRef(null);
  const { openLightbox } = useLightbox();
  const onFirstImgLoad = () => {
    // console.log("image loaded");
    setLoading(false);
    clearTimeout(timeoutRef.current);
  }
  const onFirstImgError = () => {
    // console.log("image error");
    setHasError(true);
    setLoading(false);
    clearTimeout(timeoutRef.current);
  }
  
  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      console.log("image timeout");
      setHasError(true);
      setLoading(false);
      clearTimeout(timeoutRef.current);
    }, 10000);
    return () => {
      timeoutRef.current = null;
    }
    // eslint-disable-next-line
  }, []);
  
  return (
    <>
    {!imageUrl &&
      <img src={coverFallback} alt="book cover preview" />
    }
    {imageUrl &&
      <img src={imageUrl} onError={(e)=>{e.target.onerror = null; e.target.src = coverFallback;}} alt="book cover preview" onClick={openLightbox} />
    }
    <div className="mt-2">
      <button type="button" className="btn btn-primary mr-2 mb-2" onClick={() => { let idx = imageUrl ? 1 : 0; openLightbox(idx); }} style={loading || hasError ? {display: "none"} : {}}>
          View Inside
      </button>
      {(typeof onClickVideo === 'function') &&
        <button type="button" className="btn btn-primary mr-2 mb-2" onClick={(e) => { e.preventDefault(); onClickVideo(); }}>Video</button>
      }
    </div>
    <div style={{ display: 'none' }}>
    <SRLWrapper options={lbOptions}>
        <img src={imageUrl} alt="Cover" />
        {images?.map(function (img, i) {
          if (i === 0) {
            return <img key={i} src={img} alt="" onLoad={onFirstImgLoad} onError={onFirstImgError} />;
          }
          return <img key={i} src={img} alt="" />;
        })}
    </SRLWrapper>
    </div>
    </>
  )
}

const BookCoverWithPreview = ({ isbn, isOffline, imageUrl, onClickVideo, ss }) => {
  
  const [images, setImages] = useState([]);
  const [marketing, setMarketing] = useState([]);
  
  useEffect(() => {
    if (!isOffline) {
      request(
        `${ss ? process.env.REACT_APP_API_SS : process.env.REACT_APP_API}/1/service/isbn/${isbn}?folderName=internal_images`
      ).then((data) => {
        // console.log("internal", data);
        setImages(data);
      }).catch((error) => {
        // console.error(error);
      });
      if (ss) {
        request(
          `${ss ? process.env.REACT_APP_API_SS : process.env.REACT_APP_API}/1/service/isbn/${isbn}?folderName=marketing`
        ).then((data) => {
          // console.log("marketing", data);
          setMarketing(data);
        }).catch((error) => {
          // console.error(error);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  if (ss) {
    return (
      <div className="pt-5 pt-md-0 book-cover">
        <div className="book-cover-wrapper">
          {imageUrl &&
            <SimpleReactLightbox><CoverPreview imageUrl={imageUrl} /></SimpleReactLightbox>
          }
          {!imageUrl &&
            <img src={coverFallback} alt="book cover preview" />
          }
          <div className="mt-2">
            {(images?.length > 0) &&
              <SimpleReactLightbox><InternalPreview images={images} buttonText="View Inside" /></SimpleReactLightbox>
            }
            {(marketing?.length > 0) &&
              <SimpleReactLightbox><InternalPreview images={marketing} buttonText="Marketing" /></SimpleReactLightbox>
            }
            {(typeof onClickVideo === 'function') &&
              <button type="button" className="btn btn-primary mr-2 mb-2" onClick={(e) => { e.preventDefault(); onClickVideo(); }}>Video</button>
            }
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div className="pt-5 pt-md-0 book-cover">
      <div className="book-cover-wrapper">
        <SimpleReactLightbox>
          <CombinedPreview imageUrl={imageUrl} images={images} onClickVideo={onClickVideo} />
        </SimpleReactLightbox>
      </div>
    </div>
  );
}

export default BookCoverWithPreview;
