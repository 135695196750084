import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Spinner from 'react-bootstrap/Spinner';

import './loader.scss';

const Loader = (props) => {
  return (
    <div className={classnames("loader", props.type, props.bgColor, props.className)}>
      <div className="loader__inner">
        <Spinner animation="border" role="status" variant={props.spinnerColor} />
        {props.children && <div>{props.children}</div>}
      </div>
    </div>
  );
}

Loader.propTypes = {
  type: PropTypes.oneOf(['overlay', 'placeholder']),
  bgColor: PropTypes.oneOf(['primary', 'secondary', 'light', 'dark', 'white', 'transparent']),
  spinnerColor: PropTypes.oneOf(['primary', 'secondary', 'light', 'dark', 'white'])
};

Loader.defaultProps = {
  type: 'overlay',
  bgColor: 'white',
  spinnerColor: 'dark'
};

export default Loader;
