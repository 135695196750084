import React from 'react';
import { withRouter } from 'react-router';
import { request } from '../functions/apiRequestWrapper';
import BookStack from '../components/BookStack';
import Breadcrumb from '../components/Breadcrumb';
import Loader from '../components/Loader';
import { PATH_SAVED_ORDERS, PATH_ORDER, PATH_ACCOUNT } from '../constants';
import { Download } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import { saveAs } from 'file-saver';
import BookOrderDetailsItem from '../components/BookOrderDetailsItem';
import { getValues } from '../functions/getValues';
import { getTotalItems } from '../functions/helpers';

import { connect } from 'react-redux';
import { logout } from '../redux/User';
const mapStateToProps = (state, ownProps) => {
    return {
        isLoadingCart: state.cart.isLoadingCart,
        error: state.cart.error,
        currentCart: state.cart.currentCart ? state.cart.currentCart : {},
    };
}
const mapDispatchToProps = dispatch => {
    return {
        logout: () => { dispatch(logout()); }
    }
}

class Order extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            downloading: false,
            error: false,
            orderData: null,
        }
    }

    componentDidMount() {
        setTimeout(() => {window.scrollTo(0, 0);}, 100);
        const { match } = this.props;
        this.getOrder(match.params.orderId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.orderId && prevProps.match.params.orderId !== this.props.match.params.orderId) {
            this.getOrder(this.props.match.params.orderId);
        }
    }

    getOrder = (orderId) => {
        this.setState({ loading: true, error: false });
        return request(
            `${process.env.REACT_APP_API}/1/orders/${orderId}`
        ).then((data) => {
            // console.log(data);
            this.setState({ orderData: data, loading: false });
        }).catch((error) => {
            if (error.status === 401) {
                this.props.logout();
            } else {
                console.error(error);
                let msg = "An error has occured";
                if (error.body && error.body.message) {
                    msg = error.body.message;
                }
                this.setState({ loading: false, error: msg });
            }
        });
    }

    //*** EXPORT PDF
    exportPdf = () => {

        this.setState({ downloading: true });
        request(
            `${process.env.REACT_APP_API}/1/orders/${this.state.orderData.id}/pdf`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/pdf',
                    'Content-Type': 'application/pdf'
                }
            }

        ).then((data) => {
            var filename = this.getDownloadFilename(this.state.orderData.id, "pdf");
            data.blob().then(x => saveAs(x, filename));
            this.setState({ downloading: false });

        }).catch((error) => {
            console.error(error);
            let msg = "An error has occured";
            if (error.body && error.body.message) {
                msg = error.body.message;
            }
            this.setState({ downloading: false, error: msg });
        });
    }

    //*** EXPORT CSV
    exportCsv = () => {
        this.setState({ downloading: true });
        request(
            `${process.env.REACT_APP_API}/1/orders/${this.state.orderData.id}/csv`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'text/csv',
                    'Content-Type': 'text/csv'
                }
            }

        ).then((data) => {
            var filename = this.getDownloadFilename(this.state.orderData.id, "csv");
            data.blob().then(x => saveAs(x, filename));
            this.setState({ downloading: false });

        }).catch((error) => {
            console.error(error);
            let msg = "An error has occured";
            if (error.body && error.body.message) {
                msg = error.body.message;
            }
            this.setState({ downloading: false, error: msg });
        });
    }


    getDownloadFilename = (id, ext) => {
      let filename = `order-${id}.${ext}`;
      return filename;
    }


    render() {
        const { match } = this.props;
        const { orderData, loading, error, downloading } = this.state;
        let crumbs = [];
        if (match.path === `${PATH_ORDER}/:orderId`) {
            crumbs = [{ label: "Account", link: PATH_ACCOUNT }];
        }
        if (match.path === `${PATH_SAVED_ORDERS}/:orderId`) {
            crumbs = [{ label: "Saved Orders", link: PATH_SAVED_ORDERS }];
        }
        return (
            <div className="page order-details container px-3 px-md-5">
                <Breadcrumb crumbs={crumbs}>{orderData && orderData.id ? `Order ${orderData.id}` : "..."}</Breadcrumb>

                {loading && <Loader type="placeholder" />}

                {(!loading && error) && <p className="alert alert-danger my-5">{error}</p>}

                {(!loading && orderData) &&
                    <div>

                        <div className="row">
                            <div className="col">
                                <h1>Order Details</h1>
                            </div>
                            <div className="col-12 col-md text-right">
                                <div className="downloads">
                                    <Button
                                        variant="outline-primary"
                                        className="mr-2 mb-2"
                                        onClick={() => this.exportPdf()}
                                        disabled={downloading}>
                                        <Download size={16} className="mr-1" />
                                        Export to PDF
                                    </Button>
                                    <Button
                                        variant="outline-primary"
                                        className="mr-2 mb-2"
                                        onClick={() => this.exportCsv()}
                                        disabled={downloading}>
                                        <Download size={16} className="mr-1" />
                                        Export to CSV
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className="cart-header">
                            <div className="row justify-content-center">
                                <div className="col-11 col-md-10">
                                    <div className="row">

                                        <div className="col col-12 col-sm-4 col-md-4">
                                            <div className="pt-5 pt-md-0 book-cover">
                                                <div className="book-cover-wrapper">
                                                    <BookStack
                                                        size="300"
                                                        images={orderData && orderData.items ? getValues(orderData.items, 'imageUrl', 4) : []}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col col-12 col-sm-8 col-md-8 row pl-sm-5">

                                            <div className="col col-12 col-lg-6">
                                                <div className="cart-details py-5">
                                                    <h1 className="mb-3">
                                                        {new Date(orderData.createDate).toLocaleString('en-AU', { year: 'numeric', month: 'long', day: 'numeric' })}
                                                    </h1>
                                                    <div className="mb-3">
                                                        {orderData.items.length} titles
                                                        <br />{getTotalItems(orderData.items)} total items
                                                    </div>
                                                    <div className="mb-1">
                                                        Total: {orderData.amount}
                                                    </div>
                                                    <div className="mb-1">
                                                        Order: {orderData.id}
                                                    </div>
                                                    <div className="mb-1">
                                                        Account: {orderData.accountId}
                                                    </div>
                                                    {orderData.extraAccountId && orderData.extraAccountId !== '' &&
                                                        <div className="mb-1">
                                                            Account Override: {orderData.extraAccountId}
                                                        </div>
                                                    }
                                                    {orderData.orderReference && orderData.orderReference !== '' &&
                                                        <div className="mb-1">
                                                            Ref: {orderData.orderReference}
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                            <div className="col col-12 col-lg-6 order-note">
                                                <div>
                                                    <h1>Notes</h1>
                                                    <p>
                                                        {orderData.notes}
                                                    </p>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            {orderData.items && orderData.items.map((product, i) => {
                                return (
                                    <div key={product.isbn} className="col-6 col-md">
                                        <BookOrderDetailsItem className="mb-5" {...product} />
                                    </div>
                                );
                            })}
                        </div>

                    </div>
                }
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Order));
