import Logo from '../../components/Logo';
import './maintenance.scss';

const Maintenance = () => {
  return (
    <div className="page maintenance">
      <div className="text-center text-light py-5"><Logo variant="large" /></div>
      <div className="container mb-5">
        <div className="bg-white p-5 text-center">
          <div className="mb-3 text-primary">
            <svg width="36" height="36" fill="currentColor" viewBox="0 0 16 16">
              <path d="m9.97 4.88.953 3.811C10.159 8.878 9.14 9 8 9c-1.14 0-2.158-.122-2.923-.309L6.03 4.88C6.635 4.957 7.3 5 8 5s1.365-.043 1.97-.12m-.245-.978L8.97.88C8.718-.13 7.282-.13 7.03.88L6.275 3.9C6.8 3.965 7.382 4 8 4c.618 0 1.2-.036 1.725-.098zm4.396 8.613a.5.5 0 0 1 .037.96l-6 2a.5.5 0 0 1-.316 0l-6-2a.5.5 0 0 1 .037-.96l2.391-.598.565-2.257c.862.212 1.964.339 3.165.339s2.303-.127 3.165-.339l.565 2.257 2.391.598"/>
            </svg>
          </div>
          <h3 className="mb-4">Site under maintenance</h3>
          <p>Sorry. We’re doing some scheduled maintenance now. We will be back very shortly.</p>
        </div>
      </div>
    </div>
  );
}

export default Maintenance;
